import React from 'react'
import styles from './Tooltip.module.scss'
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
    customTooltip: {
        backgroundColor: '#299D74',
        fontSize: 12.5,
        padding: 5,
        whiteSpace: 'pre-line'
    },
    customArrow: {
        color: '#299D74',
    }
}));

const TooltipHelper = ({ info }) => {
    const classes = useStyles();
    return (
        info?.length > 0
        && <Tooltip className={styles.root}
            classes={{ tooltip: classes.customTooltip, arrow: classes.customArrow }}
            arrow
            placement="top-end"
            title={info}>
            <span className={styles.tooltipSign}>?</span>
        </Tooltip>
    )
}

TooltipHelper.propTypes = {
    info: PropTypes.string.isRequired
}

export default TooltipHelper;
