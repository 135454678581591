import React from 'react';
import {
    DatePicker as DatePickerWidget,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import {
    unstable_createMuiStrictModeTheme as createMuiTheme,
} from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import DayjsUtils from '@date-io/dayjs';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import PropTypes from 'prop-types'

const darkGreenPrimary = "#1a2f2f";
const whitePrimary = "#f8f9fb";
const whiteSecondary = "#9b9ea9";
const lightGreenPrimary = "#299d74";

const materialTheme = createMuiTheme({
    overrides: {
        MuiInputBase: {
            root: {
                color: whitePrimary,
                borderBottom: whitePrimary,
                fontFamily: "Montserrat"
            },
            input: {
                textAlign: "center"
            }
        },
        MuiInput: {
            input: {
                cursor: 'pointer',
                width: '100px',
                fontSize: 16.5,
            },
            underline: {
                '&:hover:not(.Mui-disabled):before': {
                    borderBottom: ` 1px solid ${whitePrimary}`
                },
                '&::after': {
                    borderBottom: `1px solid ${whitePrimary}`
                },
                '&::before': {
                    borderBottom: `1px solid ${whitePrimary}`,
                }
            }
        },
        MuiPickersToolbarText: {
            toolbarTxt: {
                color: whitePrimary,
                fontFamily: 'Montserrat'
            },
            toolbarBtnSelected: {
                color: whitePrimary
            },
        },
        MuiPickersBasePicker: {
            pickerView: {
                backgroundColor: darkGreenPrimary
            },
        },
        MuiPickersToolbar: {
            toolbar: {
                backgroundColor: darkGreenPrimary,
            },
        },

        MuiPickersCalendarHeader: {
            dayLabel: {
                color: whitePrimary
            },
            switchHeader: {
                color: whitePrimary,
            },
            iconButton: {
                backgroundColor: darkGreenPrimary,
                color: whitePrimary
            },
        },
        MuiTypography: {
            colorInherit: {
                color: whitePrimary,
            },
            body1: {
                fontFamily: "Montserrat"
            },
            colorPrimary: {
                color: whitePrimary,
            }
        },
        MuiPickersDay: {
            day: {
                color: whitePrimary,
            },
            daySelected: {
                color: whitePrimary,
                backgroundColor: lightGreenPrimary,
                '&:hover': {
                    backgroundColor: lightGreenPrimary,
                },
                '& span': {
                    '& p': {
                        color: whitePrimary
                    }
                }
            },
            dayDisabled: {
                '& span': {
                    '& p': {
                        color: whiteSecondary
                    }
                }
            },
            current: {
                borderRadius: '50%',
                border: `1px solid ${lightGreenPrimary}`
            },
        },
        MuiDialogActions: {
            root: {
                backgroundColor: darkGreenPrimary
            }
        },
        MuiButton: {
            textPrimary: {
                color: lightGreenPrimary
            }
        }
    },
});

const RangeDatePicker = ({
    selectedStartDate,
    selectedEndDate,
    setSelectedStartDate,
    setSelectedEndDate
}) => {
    return (
        <MuiPickersUtilsProvider utils={DayjsUtils}>
            <ThemeProvider theme={materialTheme}>
                <CalendarIcon style={{ alignSelf: 'center', marginRight: 5 }} />
                <DatePickerWidget
                    value={selectedStartDate}
                    format="YYYY-MM-DD"
                    onChange={setSelectedStartDate}
                    placeholder="Start"
                    clearable
                    maxDate={selectedEndDate ?? undefined}
                />
                <span style={{ alignSelf: 'center' }}>-</span>
                <DatePickerWidget
                    value={selectedEndDate}
                    format="YYYY-MM-DD"
                    onChange={setSelectedEndDate}
                    placeholder="End"
                    clearable
                    minDate={selectedStartDate ?? undefined}
                />
            </ThemeProvider>
        </MuiPickersUtilsProvider>
    )
}

RangeDatePicker.propTypes = {
    selectedStartDate: PropTypes.object,
    selectedEndDate: PropTypes.object,
    setSelectedStartDate: PropTypes.func.isRequired,
    setSelectedEndDate: PropTypes.func.isRequired,
}

export default RangeDatePicker
