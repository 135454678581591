import React from "react";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const ModalContent = ({ onClick, imgSrc }) => {

    return (
        <Lightbox
            mainSrc={imgSrc}
            onCloseRequest={() => onClick()}
        />
    );
};
export default ModalContent