import React, { useMemo, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import Table from 'pages/common/table/Table';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import AddUserForm from './add-user-form/AddUserForm';
import EditUserForm from './edit-user-form/EditUserForm';

const GET_USERS = gql`
    query getUsers {
        users {
            id
            name
            email
            locale
        }
    }
`;

const UsersTable = ({ className, showAddPopup, handleCloseAddPopup }) => {
    const { data, refetch } = useQuery(GET_USERS);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const columnsMapping = useMemo(() => ({
        'e-mail': {
            getValue: row => row.email
        },
        'name': {
            getValue: row => row.name,
            classes: {
                isTitleCase: true,
            }
        },
        'localization': {
            getValue: row => row.locale
        },
        '': {
            getValue: (row) => <button onClick={() => setSelectedUserId(row.id)}>
                <EditIcon />
            </button>,
            classes: {
                noPadding: true
            }
        }
    }), []);

    return (
        <>
            {/*<Table*/}
            {/*    className={className}*/}
            {/*    columnsMapping={columnsMapping}*/}
            {/*    data={data?.users}*/}
            {/*/>*/}
            {
                showAddPopup &&
                <AddUserForm
                    onClose={handleCloseAddPopup}
                    onSubmitSuccess={() => refetch()}
                />
            }
            {
                selectedUserId &&
                <EditUserForm
                    id={selectedUserId}
                    onClose={() => setSelectedUserId(null)}
                    onSubmitSuccess={() => refetch()}
                />
            }
        </>
    )
}

export default UsersTable
