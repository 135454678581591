import { gql, useMutation, useQuery } from '@apollo/client';
import Dropdown from 'pages/common/dropdown/Dropdown';
import Popup from 'pages/common/popup/Popup';
import React, { useState, useEffect } from 'react';
import useAlerts from 'utility/hooks/useAlerts';
import ENTITY_ROLE_TYPES from 'utility/types/entityRoleTypes';
import styles from './EntityRoleForm.module.scss';
import ENTITY_TYPES from 'utility/types/entityTypes';

const UPDATE_ENTITY_ROLE = gql`
    mutation updateEntityRole($entityRole: UpdateEntityRoleInput!) {
        updateEntityRole(entityRole: $entityRole)
    }
`;

const GET_USERS = gql`
    query getUsers {
        users {
            id
            email
            name
            systemRole
            organization_id
            organization_type
            
            
        }
    }
`;
//what to do with dups
const GET_FIELDS = gql`
    query getFields ($filters: FieldsFiltersInput){
        fields (filters: $filters){
            field_id
            field_name
        }
    }
`;

const GET_FARMS = gql`
    query getFarms ($filters: FieldsFiltersInput){
        farms (filters: $filters){
            farm_name
        }
    }
`;

const GET_CUSTOMERS = gql`
    query getCustomers ($filters: CustomersFiltersInput){
        customers (filters: $filters){
            customer_id
            customer_name
        }
    }
`;

const GET_CUSTOMER = gql`
    query getCustomer ($filters: CustomersFiltersInput){
        customerById (filters: $filters){
            customer_id
            customer_name
        }
    }
`;

const GET_RETAILER = gql`
    query getRetailer ($filters: RetailersFiltersInput){
        retailerById (filters: $filters){
            retailer_id
            retailer_name
        }
    }
`;

const GET_ENTITY_ROLE = gql`
    query getEntityRole($id: ID!) {
        entityRoleById(id: $id) {
            role
            entityType
            user {
                id
                email
                name
            }
            entity {
                id
                name
            }
        }
    }
`;


const EntityRoleForm = ({ onClose, onSubmitSuccess, entityRoleId }) => {
    const isEditMode = !!entityRoleId;

    const { createSuccessAlert, updateSuccessAlert, errorAlert } = useAlerts();
    const [updateEntityRole] = useMutation(UPDATE_ENTITY_ROLE);
    const [userChoice, setUserChoice] = useState('customer');
    const { loading: entityRoleLoading } = useQuery(GET_ENTITY_ROLE, {
        variables: { id: entityRoleId },
        onCompleted: ({ entityRoleById }) => {
            const { __typename, ...entityRoleDetails } = entityRoleById;
            setEntityRoleForm({ ...entityRoleDetails });

        },
        fetchPolicy: "no-cache",
        skip: !isEditMode
    });
    const { data: usersData, loading: usersLoading } = useQuery(GET_USERS);
    const [entityRoleForm, setEntityRoleForm] = useState({
        user: null,
        role: null,
        entityType: ENTITY_TYPES.Field,
        entity: null,
    });
    
    const { data: entitiesData, loading: entitiesLoading,  refetch } = useQuery((() => {
                switch (entityRoleForm.entityType) {
                    case ENTITY_TYPES.Field:
                        return GET_FIELDS;
                    case ENTITY_TYPES.Farm:
                        return GET_FARMS;
                    case ENTITY_TYPES.Customer:
                        if (entityRoleForm.user?.organization_type=='retailer' ) {
                            return GET_CUSTOMERS
                        }
                        else {
                            return GET_CUSTOMER;
                        }
                    case ENTITY_TYPES.Retailer:
                        return GET_RETAILER;
                    default:
                        throw new Error(`no match found for entityType=${entityRoleForm.entityType}`);

                }
        })(),
        {
            skip: !entityRoleForm.user  || entityRoleForm.user === null,
            fetchPolicy: 'no-cache',
            variables: {
                filters: {
                    systemRole: entityRoleForm.user?.systemRole || null,
                    organization_id:entityRoleForm.user?.organization_id || null,
                    organization_type: entityRoleForm.user?.organization_type || null
                }
            },

        });

    useEffect(() => {
        if (entityRoleForm.user && entityRoleForm.user.systemRole) {
            refetch();
        }
    }, [entityRoleForm, refetch]);



    const getEntityOptions = ()=>{
            switch (entityRoleForm.entityType) {
                case ENTITY_TYPES.Field:
                    return 'fields';
                case ENTITY_TYPES.Farm:
                    return 'farms';
                case ENTITY_TYPES.Customer:
                    if (entityRoleForm.user?.organization_type=='retailer') {return 'customers'}
                    else {
                        return 'customerById'};
                case ENTITY_TYPES.Retailer:
                    return 'retailerById';
                default:
                    throw new Error(`no match found for entityType=${entityRoleForm.entityType}`);
    }
};
    const options = getEntityOptions();

    const EntityTypeName = ()=>{
        switch (entityRoleForm.entityType) {
            case ENTITY_TYPES.Field:
                return 'field_name';
            case ENTITY_TYPES.Farm:
                return 'farm_name';
            case ENTITY_TYPES.Customer:
                return 'customer_name';
            case ENTITY_TYPES.Retailer:
                return 'retailer_name';
            default:
                throw new Error(`no match found for entityType=${entityRoleForm.entityType}`);
        }
    };
    const TypeNameOptions = EntityTypeName();

    const EntityTypeID = ()=>{
        switch (entityRoleForm.entityType) {
            case ENTITY_TYPES.Field:
                return 'field_id';
            case ENTITY_TYPES.Farm:
                return 'farm_name';
            case ENTITY_TYPES.Customer:
                return 'customer_id';
            case ENTITY_TYPES.Retailer:
                return 'retailer_id';
            default:
                throw new Error(`no match found for entityType=${entityRoleForm.entityType}`);
        }
    };
    const TypeID = EntityTypeID();
    const uniqueEntitiesData = entitiesData? [...new Set(entitiesData[options])]: [];

    const [isFormDirty, setIsFormDirty] = useState(false)
    
    const handleFieldChange = (field, value) => {
        setEntityRoleForm(prevEntityRoleForm => ({
            ...prevEntityRoleForm,
            [field]: value,
        }));

    }
    const handleUserFieldChange = (field, value) => {
        setEntityRoleForm(prevEntityRoleForm => ({
            ...prevEntityRoleForm,
            [field]: value,
        }));
        setUserChoice(value.organization_type)


    }

    const handleEntityTypeChange = (field, value) => {
        handleFieldChange('entity', null);
        handleFieldChange(field, value);
    }

    const handleSubmit = async () => {
        setIsFormDirty(true);

        const requiredFields = ['entityType', 'user', 'entity'];
        if (requiredFields.some(field => !entityRoleForm[field])) {
            return;
        }

        try {
            const { user, entity, ...entityRoleDetails } = entityRoleForm;
            await updateEntityRole({
                variables: {
                    entityRole: {
                        ...entityRoleDetails,
                        userId: user.id,
                        entityId: entity[TypeID],
                    }
                }
            });
        } catch (error) {
            errorAlert(error.message);
            return;
        }

        isEditMode ? updateSuccessAlert() : createSuccessAlert();
        onClose();
        onSubmitSuccess();
    }

    const getRoleOptions = () => [null, ...Object.values(ENTITY_ROLE_TYPES)];
    return (
        <Popup
            onClose={onClose}
            onConfirm={handleSubmit}
            title={isEditMode ? 'Edit role' : 'Add new role'}
            confirmText={isEditMode ? 'save' : 'add'}
        >
            {
                (isEditMode && entityRoleLoading)
                    ? 'Loading...'
                    :
                    <div className={styles.contentWrapper}>
                        <Dropdown
                            label="e-mail"
                            name="user"
                            displayValue="email"
                            options={usersData?.users.filter(item => item.email !== 'admin@gmail.com')}
                            value={entityRoleForm.user}
                            onChange={handleUserFieldChange}
                            isLoading={usersLoading}
                            errorMessage={'required'}
                            hasError={isFormDirty && !usersData.user?.id}
                            disabled={isEditMode}
                        />
                        <Dropdown
                            label="entity"
                            name="entityType"
                            options={userChoice === "retailer" ? Object.values(ENTITY_TYPES) :Object.values(ENTITY_TYPES).filter(item => item !== 'Retailer') }
                            value={entityRoleForm.entityType}
                            onChange={handleEntityTypeChange}
                            errorMessage={'required'}
                            // hasError={isFormDirty && !entityRoleForm.entityType}
                            disabled={isEditMode}
                        />
                        <Dropdown
                            label="entity name"
                            name="entity"
                            displayValue={TypeNameOptions}
                            options={uniqueEntitiesData}
                            value={entityRoleForm.entity}
                            onChange={handleFieldChange}
                            isLoading={entitiesLoading}
                            errorMessage={'required'}
                            hasError={isFormDirty && !entityRoleForm.entity?.id}
                            disabled={isEditMode}
                        />
                        <Dropdown
                            label="role"
                            name="role"
                            options={getRoleOptions()}
                            value={entityRoleForm.role}
                            onChange={handleFieldChange}
                        />
                    </div>
            }
        </Popup>
    )
};

export default EntityRoleForm;