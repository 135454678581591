import { gql, useMutation } from '@apollo/client';
import Button from 'pages/common/button/Button';
import Input from 'pages/common/input/Input';
import PasswordInput from 'pages/common/password-input/PasswordInput';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import ROUTES from 'routes';
import useAlerts from 'utility/hooks/useAlerts';
import styles from './LoginPage.module.scss';
import loginVideo from 'assets/videos/login-video.mp4';
import { ReactComponent as Logo } from 'assets/icons/logo.svg';
import clsx from 'clsx';

const LOGIN = gql`
    mutation login($loginDetails: LoginDetailsInput!) {
        login(loginDetails: $loginDetails)
    }
`;

const LoginPage = ({ onLoginSuccess }) => {
    const history = useHistory();
    const { errorAlert } = useAlerts();
    const [login] = useMutation(LOGIN);
    const [loginForm, setLoginForm] = useState({
        email: "",
        password: ""
    });
    const [isFormDirty, setIsFormDirty] = useState(false);

    useEffect(() => {
        if (localStorage.getItem('t')) {
            history.replace(ROUTES.HOME)
        }
    }, [history])

    const handleFieldChange = (field, value) => {
        setLoginForm(prevForm => ({
            ...prevForm,
            [field]: value,
        }));
    }

    const handleSubmit = async () => {
        setIsFormDirty(true);

        const requiredFields = ['email', 'password']
        if (requiredFields.some(field => !loginForm[field])) {
            return;
        }

        try {
            const { data } = await login({ variables: { loginDetails: loginForm } });
            const { login: token } = data;

            localStorage.setItem('t', token);
            onLoginSuccess();
            history.push(ROUTES.HOME);
        } catch (error) {
            errorAlert(error.message);
            return;
        }
    }

    return (
        <div className={styles.root}>
            <div className={styles.firstSectionWrapper}>
                <div className={styles.firstSection}>
                    <Logo className={clsx(styles.logo, styles.largeMarginBottom)} />
                    <div className={clsx(styles.title, styles.largeMarginBottom)}>Login in to your account</div>
                    <Input
                        classes={{
                            root: clsx(styles.input, styles.emailInput),
                            fieldWrapper: styles.fieldWrapper,
                            field: styles.field,
                            label: styles.label
                        }}
                        label="Email Address"
                        name="email"
                        value={loginForm.email}
                        errorMessage={'required'}
                        hasError={isFormDirty && !loginForm.email}
                        onChange={handleFieldChange}
                    />
                    <PasswordInput
                        classes={{
                            root: clsx(styles.passwordInput, styles.largeMarginBottom),
                            iconButton: styles.passwordVisibilityIcon,
                            input: {
                                root: styles.input,
                                fieldWrapper: styles.fieldWrapper,
                                field: styles.field,
                                label: styles.label
                            }
                        }}
                        value={loginForm.password}
                        errorMessage={'required'}
                        hasError={isFormDirty && !loginForm.password}
                        onChange={handleFieldChange}
                    />
                    <Button className={clsx(styles.loginButton, styles.largeMarginBottom)} onClick={handleSubmit}>
                        login
                    </Button>
                    <div className={clsx(styles.contactUsText, styles.noMarginBottom)}>Interested in becoming a smarter farmer?</div>
                    <a className={styles.contactUsLink} href="mailto: info@greeneye.ag" target="_blank" rel="noreferrer">contact our team</a>
                    <a className={styles.websiteLink} href="https://greeneye.ag/" target="_blank" rel="noreferrer">Greeneye.ag</a>
                    <div className={clsx(styles.otherLinks, styles.noMarginBottom)}>Privacy Policy | Terms and conditions</div>
                    <div className={styles.trademarkText}>©2021 Centure Applications Ltd, All rights reserved</div>
                </div>
            </div>
            <div className={styles.secondSectionWrapper}>
                <video className={styles.video} src={loginVideo} autoPlay muted loop playsInline />
            </div>
        </div>
    )
};

export default LoginPage;
