import React, { useState, useEffect, useCallback } from 'react';
import PieWidget from 'pages/common/pie-widget/PieWidget';
import Widget from 'pages/common/widget/Widget';
import MachineryWidget from 'pages/common/machinery-widget/MachineryWidget';
import convertFromSquareKmToAcre from 'utility/converts/convertFromSquareKmToAcre';

const locale = {
    us: "en_US",
    il: "en_IL"
};
const MonitorWidgets = ({ operationsMetaData,operationsData,machineryName, userLocale }) => {
    const [widgets, setWidgets] = useState([]);

    const formatMetrics = useCallback((operations ,operationsData) => {
        var operation_savings_percent = null;
        if (operations.metaDataOperation && operations.metaDataOperation.savings_percent){
            operation_savings_percent = operations.metaDataOperation.savings_percent;
        }
        const aggregateAreaCovered = (accumulator, operation) => {
            if (userLocale === locale.us) {
                return accumulator + (convertFromSquareKmToAcre(operation.acres_covered.value));
            }
            else {
                return accumulator + operation.acres_covered.value;
            }
        };
        const calculateSavings = (calculated_savings) => {
            if (calculated_savings){
                return {
                    displayValue: calculated_savings,
                     pieData: [
                                {
                                     name: 'Saved',
                                     count: calculated_savings,
                                     color: '#299D74',
                                     pieTitle: "Saved"
                                 },
                                {
                                    name: 'Sprayed',
                                    count: (100 - calculated_savings),
                                    color: '#1b4b3e',
                                    pieTitle: "Sprayed"
                                 },
                                     ],
                    };
            }
            else {
                return 'No data'
            }
    }
        const widgetsMap = {
            herbicidesSaved: {
                title: 'Savings Percent',
                type: operation_savings_percent?'pie':'simple',
                aggregate: () => calculateSavings(operation_savings_percent),
                tooltip: "savings percent while using Greeneye’s SSP compared to broadcast spraying.",
                bottomSection: "",
                unit: "",
            },
            date: {
                title: 'Date',
                type: 'simple',
                aggregate: () => operationsData.start_time,
                // tooltip: "savings percent while using Greeneye’s SSP compared to broadcast spraying.",
                bottomSection: "",
                unit: "",
            },
            spray_mode: {
                title: 'Spray Mode',
                type: 'simple',
                aggregate: () => operationsData.spray_mode,
                // tooltip: "savings percent while using Greeneye’s SSP compared to broadcast spraying.",
                bottomSection: "",
                unit: "",
            },
            duration_seconds: {
                title: 'Duration',
                type: 'simple',
                aggregate: () => operationsData.duration_seconds?new Date(operationsData.duration_seconds * 1000).toISOString().slice(11, 19).toString():"",
                // tooltip: "savings percent while using Greeneye’s SSP compared to broadcast spraying.",
                bottomSection: "",
                unit: "",
            },
            sprayer_name: {
                title: 'Machinery',
                type: 'simple',
                aggregate: () => machineryName,
                // tooltip: "savings percent while using Greeneye’s SSP compared to broadcast spraying.",
                bottomSection: "",
                unit: "",
            },
        };
        const formattedWidgets = Object.values(widgetsMap).map(widgetDetails => ({
            type: widgetDetails.type,
            title: widgetDetails.title,
            value: widgetDetails.aggregate(),
            unit: widgetDetails.unit,
            tooltip: widgetDetails.tooltip,
            bottomSection: widgetDetails.bottomSection,
            rightSection: widgetDetails.rightSection,
            classes: widgetDetails.classes
        }));
        setWidgets(formattedWidgets);
    }, [userLocale]);

    useEffect(() => {
        operationsMetaData && operationsData && formatMetrics(operationsMetaData, operationsData);
    }, [formatMetrics, operationsMetaData, operationsData]);

    return (widgets.map((widget, i) => {
        if (widget.type === "pie") {
            return <PieWidget key={i} {...widget} />;
        }

        if (widget.type === "applicationType") {
            const isApplicationTypeExist = !!widget.title;
            if (isApplicationTypeExist) {
                return <Widget key={i} {...widget} />;
            }
        }
        if (widget.type === "machinery") {
            return <MachineryWidget key={i} machinery={widget} />;
        }
        if (widget.type === "simple") {
            return <Widget key={i} {...widget} />;
        }
        return null;
    }));
};

export default MonitorWidgets;
