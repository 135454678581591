import React, { useState, useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import stylesFarmDashboard from './FarmDashboardDetailsView.module.scss';
import styles from 'pages/home/HomePage.module.scss';
import PropTypes from 'prop-types';
import LoadMap from 'pages/common/map/LoadMap';
import MonitorWidgets from './monitor-widgets/MonitorWidgets';
import { useLocation } from 'react-router-dom';
const tabs = ["MONITOR", "INTELLIGENCE"];
const GET_SELECTED_OPERATIONS_DETAILS = gql`
    query getOperationsDetails  ($filters: OperationSingleFiltersInput) {
        operationById(filters: $filters){
            operation_id 
            sprayer_name
            sprayer_id
            spray_mode
            start_time
            field_name
            duration_seconds
            field{
               field_id
               location{
                lat
                lng
                }
            }
        }
    }
`;

const GET_SELECTED_FIELDS_DETAILS = gql`
    query getFieldsDetails  ($filters: FieldsFiltersInput) {
        fields(filters: $filters) {
            operations {
                operation_id 
                sprayer_name
                spray_mode
                duration_seconds
                }
        }
    }
`;


const GET_SPRAYERS = gql`
  query GetSprayers($filters: SprayersFiltersInput) {
    sprayer(filters: $filters)
  }
`;
const GET_CURRENT_USER_LOCALE = gql`
    query getCurrentUserLocale {
        me {
            locale
        }
    }
`;
const GET_SELECTED_OPERATIONS_HEATMAP = gql`
    query getOperationsHeatMapDetails  ($filters: OperationSingleFiltersInput) {
      HeatmapOperation(filters: $filters){
        zoom_1{   
            point1_lng 
            point1_lat
            point2_lng 
            point2_lat 
            pcnt 
            images
            }
        zoom_2{
            point1_lng                        
            point1_lat 
            point2_lng 
            point2_lat 
            pcnt 
            images
            }
        zoom_3{   
            point1_lng 
            point1_lat 
            point2_lng 
            point2_lat 
            pcnt 
            images
            }
        zoom_4{ 
            point1_lng 
            point1_lat 
            point2_lng 
            point2_lat 
            pcnt 
            images
            }
        zoom_5{   
            point1_lng
            point1_lat
            point2_lng
            point2_lat
            pcnt
            images
            }        
        }
      }
`;

const GET_SELECTED_OPERATION_METADATA_DETAILS = gql`
    query getMetaDataOperationsDetails  ($filters: OperationSingleFiltersInput) {
        metaDataOperation(filters: $filters){
          savings_percent
          acres_covered
        }
    }
`;
const FarmDashboardDetailsView = ({ isFieldsTable, handleSectionChange, isHomePageTable }) => {
    // let { selectedOriginIds, selectedIds } = useParams();
    const location = useLocation();
    const { selectedIds, selectedOriginIds } = location.state;
    useQuery(isFieldsTable ? GET_SELECTED_FIELDS_DETAILS : GET_SELECTED_OPERATIONS_DETAILS, {
        variables: {
            filters: {
                ids: selectedIds
            }
        },
        onCompleted: data => {
            if (isFieldsTable) {
                setFieldsPolygonsArr(data.fields.map(({ polygon, id }) => ({ polygon, id })));
                setOperationsData(data.fields
                    .filter(({ operations }) => !!operations)
                    .flatMap(({ operations }) => operations));
            } else {

                setFieldsPolygonsArr(
                    ({ polygon: data.operationById.field?.location, id: data.operationById.operation_id })
                );

                // setOperationPolygonArr(data.operations.map(operation =>
                //     ({ polygon: operation.operationPolygon, id: operation.id })
                // ));
                setOperationsData(data.operationById);
            }
        }
    });
    useQuery(GET_CURRENT_USER_LOCALE, {
        onCompleted: data => {
            setUserLocale(data.me.locale);
        },
    });
    useQuery(GET_SELECTED_OPERATIONS_HEATMAP, {
        variables: {
            filters: {
                ids: selectedOriginIds
            }
        },
        onCompleted: data => {
            setOperationsHeatMap(data)
        },
        onError: () =>{
            setHeatMapFlag(false)
        }
    });


    useQuery(GET_SELECTED_OPERATION_METADATA_DETAILS, {
        variables: {
            filters: {
                ids: selectedOriginIds
            }
        },
        onCompleted: data => {
            setOperationsMetaData(data)


        },
    });

    useEffect(() => {
        if (isHomePageTable && selectedIds.length > 0) {
            if (isFieldsTable) {
                handleSectionChange("fileds", "open");
                return;
            }
            handleSectionChange("operation", "open");
        }

    }, [handleSectionChange, isFieldsTable, isHomePageTable, selectedIds.length]);

    useEffect(() => {
        return () => {
            if (isHomePageTable) {
                handleSectionChange("", "close");
            }
        };
    }, [handleSectionChange, isHomePageTable]);

    const [currentTab, setCurrentTab] = useState(tabs[0]);
    const [fieldsPolygonsArr, setFieldsPolygonsArr] = useState([]);
    const [operationPolygonArr, setOperationPolygonArr] = useState([]);
    const [operationsData, setOperationsData] = useState({});
    const [layerOptions, setLayerOptions] = useState([{ name: 'HEAT MAP', isVisible: false, }, { name: 'FIELD IMAGES', isVisible: false, }]);
    const [userLocale, setUserLocale] = useState(null);
    const [operationsHeatMap, setOperationsHeatMap] = useState([]);
    const [operationsMetaData, setOperationsMetaData] = useState([]);
    const [featureFlagImages,] = useState(process.env.REACT_APP_FEATURE_FLAG_IMAGES === "true" ? true : false);
    const [HeatMapFlag, setHeatMapFlag] = useState(true);
    const [machineryName, setmachineryName] = useState("");


    const { loading, error, data, refetch } =
        useQuery(GET_SPRAYERS, {
        skip: Object.keys(operationsData).length === 0,
        variables: {
            filters: {
                ids: operationsData.sprayer_id
            }},

            onCompleted: data => {
                setmachineryName(data.sprayer);
            },

        });

    useEffect(() => {

        if (Object.keys(operationsData).length !== 0) {
            refetch();
        }
    }, [operationsData, refetch]);


    return (
        <div className={styles.root}>
        <div className={stylesFarmDashboard.root}>
            <div>{operationsData.field_name}</div>
            <span className={stylesFarmDashboard.operationsNumber}>
</span>
            <div className={stylesFarmDashboard.mapRoot}>
                {fieldsPolygonsArr &&

                    <LoadMap
                        HeatMapFlag={HeatMapFlag}
                        setHeatMapFlag={setHeatMapFlag}
                        operationsHeatMap={operationsHeatMap}
                        operationPolygonArr={operationPolygonArr}
                        currentTab={currentTab}
                        fieldsPolygonsArr={fieldsPolygonsArr}
                        operationsData={operationsData}
                        layerOptions={layerOptions}
                        setLayerOptions={setLayerOptions}
                        featureFlagImages={featureFlagImages}
                    />
                }
            </div>
            <div className={stylesFarmDashboard.content}>
                {machineryName &&
                    <div className={stylesFarmDashboard.widgetsContainer}>
                        <MonitorWidgets operationsMetaData={operationsMetaData} operationsData={operationsData} machineryName={machineryName} userLocale={userLocale}/>
                    </div>
                }
            </div>
        </div>
     </div>

    );
};

FarmDashboardDetailsView.propTypes = {
    isFieldsTable: PropTypes.bool,
    handleSectionChange: PropTypes.func,
    isHomePageTable: PropTypes.bool,
};

export default FarmDashboardDetailsView;
