import React, { useMemo, useState } from 'react';
import styles from './RolesTable.module.scss';
import { gql, useQuery } from '@apollo/client';
import Table from 'pages/common/table/Table';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as ViewerRoleIcon } from 'assets/icons/viewer-role.svg';
import { ReactComponent as OwnerRoleIcon } from 'assets/icons/owner-role.svg';
import clsx from 'clsx';
import ENTITY_ROLE_TYPES from 'utility/types/entityRoleTypes';
import EntityRoleForm from './entity-role-form/EntityRoleForm';

const GET_ROLES = gql`
    query getRoles {
        entityRoles {
            id
            user {
                id
                name
                email
            }
            entityType
            entity {
                id
                name
            }
            role    
        }
    }
`;

const RolesTable = ({ className, showAddPopup, handleCloseAddPopup }) => {
    const { data, refetch } = useQuery(GET_ROLES);
    const [selectedEntityRoleId, setSelectedEntityRoleId] = useState(null);

    const columnsMapping = useMemo(() => ({
        'e-mail': {
            getValue: row => row.user.email
        },
        'name': {
            getValue: row => row.user.name,
            classes: {
                isTitleCase: true,
            }
        },
        'entity': {
            getValue: row => row.entityType
        },
        'entity name': {
            getValue: row => row.entity.name
        },
        'role': {
            getValue: row =>
                <div className={styles.roleCell}>
                    {row.role === ENTITY_ROLE_TYPES.VIEWER && <ViewerRoleIcon className={styles.roleCellIcon} />}
                    {row.role === ENTITY_ROLE_TYPES.OWNER && <OwnerRoleIcon className={styles.roleCellIcon} />}
                    <div className={clsx({
                        [styles.roleCellText]: true,
                        [styles.orange]: row.role === ENTITY_ROLE_TYPES.VIEWER,
                        [styles.green]: row.role === ENTITY_ROLE_TYPES.OWNER,
                    })}>
                        {row.role.toLowerCase()}
                    </div>
                </div>,
            getValueForSort: row => row.role.toLowerCase(),
            classes: {
                isTitleCase: true,
            }
        },
        '': {
            getValue: (row) => <button onClick={() => setSelectedEntityRoleId(row.id)}>
                <EditIcon />
            </button>,
            classes: {
                noPadding: true,
            }
        }
    }), []);

    const handleClose = () => {
        if (selectedEntityRoleId) {
            setSelectedEntityRoleId(null);
        }

        handleCloseAddPopup();
    };

    return (
        <>
            {/*<Table*/}
            {/*    className={className}*/}
            {/*    columnsMapping={columnsMapping}*/}
            {/*    data={data?.entityRoles}*/}
            {/*/>*/}
            {
                (showAddPopup || selectedEntityRoleId) &&
                <EntityRoleForm
                    entityRoleId={selectedEntityRoleId}
                    onClose={handleClose}
                    onSubmitSuccess={() => refetch()}
                />
            }
        </>
    )
}

export default RolesTable;
