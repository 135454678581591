// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MachineryWidget_machineryValue__1xOvN {\n  white-space: pre-line;\n  font-size: 30px !important; }\n", "",{"version":3,"sources":["webpack://src/pages/common/machinery-widget/MachineryWidget.module.scss"],"names":[],"mappings":"AAAA;EACI,qBAAqB;EACrB,0BAA0B,EAAA","sourcesContent":[".machineryValue {\n    white-space: pre-line;\n    font-size: 30px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"machineryValue": "MachineryWidget_machineryValue__1xOvN"
};
export default ___CSS_LOADER_EXPORT___;
