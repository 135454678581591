import React, { useMemo, useEffect, useState } from 'react';
import Table from 'pages/common/table/Table';
import { gql, useQuery } from '@apollo/client';
import './OperationsSection.css';

const GET_MY_OPERATIONS = gql`
    query getMyOperations($filters: OperationsFiltersInput) {
        operations(filters: $filters) {
            operation_id
            origin_op_id
            duration_seconds
            spray_mode
            sprayer_name
            field_name
            start_time
        }
    }
`;
const GET_MY_LAST_OPERATIONS = gql`
    query getMyOperations($filters: OperationsFiltersInput) {
        lastOperations(filters: $filters) {
            operation_id
            origin_op_id
            duration_seconds
            spray_mode
            sprayer_name
            start_time
            
           
        }
    }
`;

const OperationsSection = ({ className, startDate, endDate, isHomePageTable, columnsFilter, handleSectionChange, fieldName}) => {
    const { data } = useQuery(isHomePageTable ? GET_MY_LAST_OPERATIONS : GET_MY_OPERATIONS,
        {
            variables: {
                filters: {
                    start_date: startDate,
                    end_date: endDate,
                    order_by_field: "start_time",
                    order_direction: "desc"
                    //     //     fieldName
                }
            }
        });


    const columnsMapping = useMemo(() => ({

        'date': {
            getValue: row => row.start_time
        },


        'field': {
            getValue: row => row.field_name,
        },

        'spray mode': {

            getValue: row => row.spray_mode
        },

        'duration': {
            getValue: row => new Date(row.duration_seconds * 1000).toISOString().slice(11, 19).toString()
        },

        'machinery': {
            getValue: row => row.sprayer_name
        },
    }), []);

    const [tempColumnsMapping, setTempColumnsMapping] = useState(columnsFilter ? columnsMapping : {});
    useEffect(() => {
        let filtered;
        if (columnsFilter && Object.keys(columnsFilter)) {
            filtered = Object.keys(columnsMapping).filter(key => columnsFilter[key]).reduce((tempObject, key) => {
                tempObject[key] = columnsMapping[key];
                return tempObject;
            }, {});
        }
        setTempColumnsMapping(filtered);
    }, [columnsFilter, columnsMapping]);


    return (
        <Table
            className={className}
            columnsMapping={columnsFilter ? tempColumnsMapping : columnsMapping}
            data={isHomePageTable ? data?.lastOperations : data?.operations.filter(item => item.start_time > '2023-04-08')}
            options={{
                multichoice: true,
            }}
            tableName={'Operations'}
        />


    );
};

export default OperationsSection;