import AdminPage from './pages/admin/AdminPage';
import Layout from './pages/layout/Layout';
import { Route, Switch } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import ROUTES from 'routes';
import LoginPage from './pages/login/LoginPage';
import "scss/main.scss";
import { SnackbarProvider } from 'notistack';
import { useState, useEffect } from 'react';
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import DebugPage from 'pages/debug/DebugPage';
import HomePage from 'pages/home/HomePage';
import FarmDashboardDetailsView from 'pages/farm-dashboard/farm-dashboard-details-view/FarmDashboardDetailsView';

import SYSTEM_ROLE_TYPES from "./utility/types/systemRoleTypes";

const GET_CURRENT_USER = gql`
    query getCurrentUser {
        me {
            name
            systemRole
            locale
        }
    }
`;

const App = () => {
    const [token, setToken] = useState(localStorage.getItem('t'));
    const [getCurrentUser, { data: currentUser, loading: currentUserLoading, client }] = useLazyQuery(GET_CURRENT_USER, {
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            setIsAdmin(data.me.systemRole === SYSTEM_ROLE_TYPES.ADMIN)

        }
    });
    const [isAdmin,setIsAdmin ]=useState(false)
    const [me, setMe] = useState({});
    useQuery(GET_CURRENT_USER, {
        onCompleted: (data) => {
            setMe(data);
            setIsAdmin(data.me.systemRole === SYSTEM_ROLE_TYPES.ADMIN)

        },
    });
    const history = useHistory();
    useEffect(() => {
        if (token && token === "t") {
            getCurrentUser();
        }
    }, [getCurrentUser, token]);

    useEffect(() => {
        const checkToken = () => {
            const newToken = localStorage.getItem('t');

            if (newToken !== token) {
                setToken(newToken);
            }

            if (!newToken) {
                client?.clearStore();
                history.replace(ROUTES.LOGIN);
            }
        };

        window.addEventListener('storage', checkToken);

        return () => {
            window.removeEventListener('storage', checkToken);
        };
    }, [client, history, token]);

    return (
        <SnackbarProvider maxSnack={1} anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
            {
                !currentUserLoading
                    ?
                    // TODO: get user profile image
                    <Layout userDetails={currentUser ? { ...currentUser?.me, profileImageUrl: 'https://i.imgur.com/3wYOeSa.jpg', userName:currentUser?.me?.name  } : { ...me?.me, profileImageUrl: 'https://i.imgur.com/3wYOeSa.jpg' , userName: me?.me?.name}}>
                        <Switch>
                            <Route exact path={ROUTES.HOME}>
                                <HomePage currentUser={currentUser ? currentUser?.me : me?.me} />
                            </Route>
                            <Route path={ROUTES.LOGIN}>
                                <LoginPage onLoginSuccess={getCurrentUser} />
                            </Route>
                            {isAdmin &&
                            <Route path={ROUTES.ADMIN}>
                                <AdminPage />
                            </Route>}
                            <Route path={ROUTES.DEBUG}>
                                <DebugPage />
                            </Route>
                           <Route path={ROUTES.MAP} component={FarmDashboardDetailsView} />
                            <Route path="*">
                                <HomePage />
                            </Route>
                        </Switch>
                    </Layout>
                    : 'Loading...'
            }
        </SnackbarProvider>
    );
};

export default App;
