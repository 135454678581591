import React from 'react'
import styles from './Checkbox.module.scss';
import { ReactComponent as CheckboxEmptyIcon } from 'assets/icons/checkbox-empty-white.svg';
import { ReactComponent as CheckboxCheckedIcon } from 'assets/icons/checkbox-checked.svg';
import clsx from 'clsx';
import PropTypes from 'prop-types'

const Checkbox = ({ isChecked, onClick, classes = {}, emptyIcon, checkedIcon }) => {
    return (
        <button className={clsx(styles.root, classes.root)} onClick={e => onClick(!isChecked, e)}>
            {
                isChecked
                    ? (checkedIcon ?? <CheckboxCheckedIcon />)
                    : (emptyIcon ?? <CheckboxEmptyIcon />)
            }
        </button>
    )
}

export default Checkbox;


Checkbox.propTypes = {
    isChecked: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    classes: PropTypes.object,
    emptyIcon: PropTypes.node,
    checkedIcon: PropTypes.node,
};
