import React, { useRef, useState } from 'react';
import styles from './Dropdown.module.scss';
import PropTypes from 'prop-types'
import clsx from 'clsx';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/black-arrow-down.svg';
import useClickAwayListener from 'utility/hooks/useClickAwayListener';
import { useEffect } from 'react';

const Dropdown = ({
    label,
    name,
    options,
    errorMessage,
    hasError,
    onChange,
    value,
    classes = {},
    displayValue,
    isLoading,
    disabled,
    displayCurrentOption = true,
}) => {
    const [showOptions, setShowOptions] = useState(false);
    const optionsPanelRef = useRef();
    useClickAwayListener(optionsPanelRef, () => setShowOptions(false));

    useEffect(() => {
        if (!isLoading && value) {
            onChange(name, value ?? options?.[0]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading]);

    const handleOptionChange = option => {
        onChange(name, option);
        setShowOptions(false);
    };

    const getDisplayValue = value => displayValue
        ? value?.[displayValue] ?? options[0]?.[displayValue]
        : value ?? options[0];

    return (
        <div className={clsx(styles.root, classes.root)}>
            <label
                className={clsx({
                    [styles.label]: true,
                    [styles.button]: !displayCurrentOption,
                }, classes.label)}
                onClick={() => (!disabled && !displayCurrentOption) && setShowOptions(!showOptions)}
            >
                {label}
            </label>
            <div className={clsx(styles.dropdownWrapper, classes.dropdownWrapper)}>
                <button
                    className={clsx({
                        [styles.currentOption]: true,
                        [styles.disabled]: disabled,
                        [styles.hide]: !displayCurrentOption
                    })}
                    onClick={() => (!disabled && displayCurrentOption) && setShowOptions(!showOptions)}
                >
                    {!isLoading ? getDisplayValue(value) : 'Loading...'}
                    <ArrowDownIcon className={styles.dropdownIcon} />
                </button>
                {
                    !isLoading && showOptions &&
                    <div className={clsx(styles.options, classes.options)} ref={optionsPanelRef}>
                        {options.map(option =>
                            <button
                                key={displayValue ? option[displayValue] : option}
                                className={clsx({
                                    [styles.option]: true,
                                    [styles.isCurrentOption]: option === value,
                                    [classes.isCurrentOption]: option === value,
                                }, classes.option)}
                                onClick={() => handleOptionChange(option)}
                            >
                                {getDisplayValue(option)}
                            </button>
                        )}
                    </div>
                }
                {
                    displayCurrentOption
                    &&
                    <span className={clsx({
                        [styles.fieldErrorMessage]: true,
                        [styles.show]: hasError,
                    })}>
                        {errorMessage}
                    </span>
                }
            </div>
        </div>
    )
};

Dropdown.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    errorMessage: PropTypes.string,
    hasError: PropTypes.bool,
    options: PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.string.isRequired,
            PropTypes.object.isRequired,
        ]),
    ),
    classes: PropTypes.object,
    displayValue: PropTypes.string,
    isLoading: PropTypes.bool,
    disabled: PropTypes.bool,
    displayCurrentOption: PropTypes.bool,
}

export default Dropdown;
