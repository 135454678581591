import React from 'react'
import DonutChart from 'react-d3-donut';
import Widget from '../widget/Widget';
import PropTypes from 'prop-types'
import styles from './PieWidget.module.scss'

const PieChart = ({ title, value, classes = {}, tooltip, dataCounter }) => {
    const pieClass = title.replaceAll(' ', '-');
    if (value?.pieData.length > 0 && !!value.pieData[0].count) {
        return (
            <Widget
                classes={{
                    rightSection: styles.pieChart,
                    bottomSection: styles.colorHelper,
                    title: styles.title,
                }}
                title={title}
                tooltip={tooltip}
                value={`${value.displayValue} ${value.displayValue ? '%' : ''}`}
                rightSection={
                    value.pieData &&
                    <DonutChart
                        innerRadius={50}
                        outerRadius={70}
                        transition={true}
                        pieClass={pieClass}
                        svgClass={pieClass}
                        strokeWidth={1}
                        data={value.pieData}
                        displayTooltip={true}
                    />
                }
                bottomSection={
                    <div className={styles.pieToolTip}>
                        {value.pieData && value.pieData.map((item, i) =>
                            <div className={styles.toolItem} key={i} style={{ borderLeft: `3px solid  ${item.color}` }}>
                                <div className={styles.tooltipItemTitle}>
                                    {item?.pieTitle}
                                </div>
                                <div className={styles.tooltipItemValue}>
                                    {item?.count?.toString()}
                                </div>
                            </div>
                        )}
                    </div>}
                dataCounterSection={
                    dataCounter && Object.keys(dataCounter)?.length
                    && <div className={styles.dataCounter}>Display {dataCounter.counter} of {dataCounter.operationsLength}</div>
                }
            />
        )
    }
    else return null

}

PieChart.propTypes = {
    title: PropTypes.string.isRequired,
    value: PropTypes.object.isRequired,
    classes: PropTypes.object,
    tooltip: PropTypes.string
}

export default PieChart
