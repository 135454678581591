import React, { useState } from 'react';
import PageHeader from './PageHeader/PageHeader';
import styles from './Layout.module.scss';
import SideMenu from './SideMenu/SideMenu';
import { useLocation } from 'react-router-dom';
import ROUTES from 'routes';

const Layout = ({ children, userDetails }) => {
    const location = useLocation();
    const [isMenuOpen, setIsMenuOpen] = useState(true)

    if (location.pathname === ROUTES.LOGIN) {
        return children;
    }
    
    return (
        <div className={styles.root}>
            <SideMenu isOpen={isMenuOpen} closeMenu={() => setIsMenuOpen(false)} userDetails={userDetails} />
            <div className={styles.pageWrapper}>
                <PageHeader openMenu={() => setIsMenuOpen(true)} profileImageUrl={userDetails.profileImageUrl} userName={userDetails.userName} />
                <div className={styles.pageContent}>
                    {children}
                </div>
            </div>
        </div>
    )
}

export default Layout;
