import {gql, useMutation, useQuery} from '@apollo/client';
import Dropdown from 'pages/common/dropdown/Dropdown';
import Input from 'pages/common/input/Input';
import PasswordInput from 'pages/common/password-input/PasswordInput';
import Popup from 'pages/common/popup/Popup';
import React, { useState } from 'react';
import useAlerts from 'utility/hooks/useAlerts';
import LOCALE_TYPES from 'utility/types/localeTypes';
import ORGANIZATION_TYPES from 'utility/types/organizationTypes';
import styles from './AddUserForm.module.scss';

const CREATE_USER = gql`
    mutation createUser($user: CreateUserInput!) {
        createUser(user: $user)
    }
`;

const GET_CUSTOMERS = gql`
    query getCustomers {
        customers {
            customer_id
            customer_name
        }
    }
`;

const GET_RETAILERS = gql`
    query getOrganizations {
        retailers {
            retailer_id
            retailer_name
        }
    }
`;

const AddUserForm = ({ onClose, onSubmitSuccess }) => {
    const { createSuccessAlert, errorAlert } = useAlerts();
    const [createUser, { error }] = useMutation(CREATE_USER);
    const [userForm, setUserForm] = useState({
        name: '',
        email: '',
        password: '',
        locale: LOCALE_TYPES.en_US,
        organization_type: '',
        organization_id: ""
    });
    const [isFormDirty, setIsFormDirty] = useState(false)
    const [entitySelected, setEntitySelected] = useState(false)
    const [userChoice, setUserChoice] = useState('retailer');
    const [userCostumer, setUserCostumer] = useState('');
    const handleFieldChange = (field, value) => {
        setUserForm(prevUserForm => ({
            ...prevUserForm,
            [field]: value,
        }));
    }
    const handleCustomerChange=(field, value) => {
        setUserCostumer(value)
        let id= userChoice === 'retailer' ? data.retailers.filter((e) => e.retailer_name === value)[0].retailer_id :
            data.customers.filter((e) => e.customer_name === value)[0].customer_id
        setUserForm(prevUserForm => ({
            ...prevUserForm,
            [field]: id,
        }));


    }

    const handleChoiceSelection = (field, value) => {
        setEntitySelected(true)
        setUserChoice(value);
        handleFieldChange('organization_type', value);
        setUserCostumer("")
    };

    let selectedQuery = '';
    if (userChoice === 'retailer') {
        selectedQuery = GET_RETAILERS;
    } else if (userChoice === 'customer') {
        selectedQuery = GET_CUSTOMERS;
    }

    const { loading, data } = useQuery(selectedQuery);


    // Handle loading and error states
    if (loading) return <p>Loading...</p>;

    const handleSubmit = async () => {
        setIsFormDirty(true);

        const requiredFields = ['name', 'email', 'password']
        if (requiredFields.some(field => !userForm[field])) {
            return;
        }

        try {
            await createUser({ variables: { user: userForm } });
        } catch (error) {
            if (error?.graphQLErrors[0]?.extensions?.validationErrorType === 'EMAIL_ALREADY_EXISTS') {
                errorAlert('Email Already Exists!');
                return;
            }
            errorAlert();
            return;
        }

        createSuccessAlert();
        onClose();
        onSubmitSuccess();
    }

    const getEmailErrorMessage = () => {
        if (userForm.email === error?.graphQLErrors[0]?.extensions?.existingEmail) {
            return error.graphQLErrors[0].message;
        }

        return 'required';
    }

    return (
        <Popup
            onClose={onClose}
            onConfirm={handleSubmit}
            title='Add new user'
            confirmText='Add'
        >
            <div className={styles.contentWrapper}>
                <Input
                    label="e-mail"
                    name="email"
                    value={userForm.email}
                    errorMessage={getEmailErrorMessage()}
                    hasError={isFormDirty && (!userForm.email || (error?.graphQLErrors[0]?.extensions?.existingEmail === userForm.email))}
                    onChange={handleFieldChange}
                />
                <Input
                    label="name"
                    name="name"
                    value={userForm.name}
                    errorMessage={'required'}
                    hasError={isFormDirty && !userForm.name}
                    onChange={handleFieldChange}
                />
                <PasswordInput
                    value={userForm.password}
                    errorMessage={'required'}
                    hasError={isFormDirty && !userForm.password}
                    onChange={handleFieldChange}
                    includePasswordGenerator={true}
                    disabled={true}
                />
                <Dropdown
                    label="localization"
                    name="locale"
                    options={Object.values(LOCALE_TYPES)}
                    value={userForm.locale}
                    onChange={handleFieldChange}
                />
                <Dropdown
                    label="organization"
                    name="organization_type"
                    options={Object.values(ORGANIZATION_TYPES)}
                    value={userForm.organization_type}
                    onChange={handleChoiceSelection}
                />
                {entitySelected && <Dropdown
                    label="customer"
                    name="organization_id"
                    options={userChoice === "retailer" ? data.retailers.map((key) => key['retailer_name']) : data?.customers?.map((key) => key['customer_name'])}
                    value={userCostumer}
                    onChange={handleCustomerChange}

                />}
            </div>
        </Popup>
    )
};

export default AddUserForm;
