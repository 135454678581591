import React, { useState } from "react";
import { Marker, InfoWindow } from "@react-google-maps/api";
import markerIcon from 'assets/icons/map-marker.svg';
import styles from './Map.module.scss'
import ModalContent from "pages/layout/modal/modal";
import { createPortal } from "react-dom";

const modalElement = document.getElementById("modal");
const Modal = ({ children }) => createPortal(children, modalElement);

export default function CustomMarker({
    position,
    clusterer,
    setActiveMarker,
    imgSrc
}) {
    const [isHover, setIsHover] = useState(false)
    const [modal, setModal] = useState(false);

    return (
        <div className={styles.marker_root}>
            <Marker
                position={position}
                clusterer={clusterer}
                icon={markerIcon}
                onClick={() => setModal(true)}
                onMouseOver={() => setIsHover(true)}
                onMouseOut={() => setIsHover(false)}
            >
                {isHover
                    &&
                    <InfoWindow onCloseClick={() => setActiveMarker(null)} >
                        <div className={styles.InfoWindow}>
                            <img src={imgSrc}
                                className={styles.map_image}
                                alt="imgSrc">
                            </img>
                            <button onClick={() => setModal(true)}>Show Modal</button>
                        </div>
                    </InfoWindow>
                }
            </Marker>
            {modal && (
                <Modal>
                    <ModalContent onClick={() => setModal(false)} imgSrc={imgSrc} />
                </Modal>
            )}
        </div>
    )
}
