import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TableView from './table-view/TableView';
import { ReactComponent as Loading } from 'assets/icons/LoadingAnimated.svg';
import { useHistory } from 'react-router-dom';
import usePagination from 'pages/common/pagination/Pagination';

const Table = ({
    columnsMapping,
    data,
    className: classNameFromParent,
    options = {},
    tableName,
    isHomePageTable,
}) => {
    const history = useHistory();
    const [currentClickedRowId, setCurrentClickedRowId] = useState(null);
    const [currentClickedOriginRowId, setCurrentClickedOriginRowId] = useState(null);
    const [selectedIds, setSelectedIds] = useState([]);
    const [selectedOriginIds, setSelectedOriginIds] = useState([]);
    const [showDetailsView, setShowDetailsView] = useState(false);
    const [currentSortDetails, setCurrentSortDetails] = useState(null);
    const [sortedData, setSortedData] = useState(data);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const getSortedData = data => {
            if (!currentSortDetails || !data?.length) {
                return data;
            }

            return [...data].sort((firstRow, secondRow) => {
                const getValueForSort = row => columnsMapping[currentSortDetails.field].getValueForSort
                    ? columnsMapping[currentSortDetails.field].getValueForSort(row)
                    : columnsMapping[currentSortDetails.field].getValue(row)?.toLowerCase() ?? '';

                if (getValueForSort(secondRow) === getValueForSort(firstRow)) {
                    return 0;
                }

                const shouldSecondRowMoveUp = currentSortDetails.isAscending
                    ? getValueForSort(secondRow) < getValueForSort(firstRow)
                    : getValueForSort(secondRow) > getValueForSort(firstRow);

                return shouldSecondRowMoveUp ? 1 : -1;
            });
        }

        setSortedData(getSortedData(data));
        setLoading(false)
    }, [data, currentSortDetails, columnsMapping])

    useEffect(() => {
        if (!currentClickedRowId && !selectedIds?.length) {
            setShowDetailsView(false);
        }
    }, [currentClickedRowId, selectedIds?.length])

    const handleCheckboxChange = (e, isChecked, currentId) => {
        e.stopPropagation();

        setSelectedIds(prevIds => isChecked
            ? [...prevIds, currentId]
            : prevIds.filter(id => id !== currentId)
        );
    }

    const handleHeaderClick = header => {
        if (header === 'select') {
            return;
        }

        if (!currentSortDetails || currentSortDetails.field !== header) {
            setCurrentSortDetails({ field: header, isAscending: true });
            return;
        }

        if (currentSortDetails.field === header && !currentSortDetails.isAscending) {
            setCurrentSortDetails(null);
            return;
        }

        if (currentSortDetails.field === header && currentSortDetails.isAscending) {
            setCurrentSortDetails({ field: header, isAscending: false });
            return;
        }
    }

    const handleSelectHeaderCheckBox = e => {
        e.stopPropagation();
        if (selectedIds.length === 0) {
            setSelectedIds(sortedData.map(data => data.id));
            setSelectedOriginIds(sortedData.map(data => data.origin_op_id));
            return
        }
        setSelectedIds([]);
    }

    const handleCellClick = (Originid, id) => {
        if (!options.multichoice) {
            return;
        }

        if (currentClickedRowId === id) {
            setCurrentClickedRowId(null)
            return;
        }
        setCurrentClickedOriginRowId(Originid);
        setCurrentClickedRowId(id);
        setShowDetailsView(true);
        history.push({pathname: `/OperationMap`,
            state: {
                selectedIds: Originid,
                selectedOriginIds: id,
            }})

    }

    const onMultichoiceConfirm = () => {
        setShowDetailsView(true);
    }

    const handleCloseDetailsView = () => {
        setShowDetailsView(false);
        setCurrentClickedRowId(null);
    }

    let [page, setPage] = useState(1);
    const PER_PAGE = 10;

    const count = Math.ceil(sortedData?.length / PER_PAGE);
    const _DATA = sortedData
    const handleChange = (e, p) => {
        setPage(p);
        _DATA.jump(p);
    };


    if (loading) {
        return <Loading style={{ background: "transparnt", margin: "auto", display: "block", shapeRendering: "auto" }} />
    }

    return (
        <>
                <TableView
                    columnsMapping={columnsMapping}
                    data={sortedData}
                    className={classNameFromParent}
                    options={options}
                    onMultichoiceConfirm={onMultichoiceConfirm}
                    handleHeaderClick={handleHeaderClick}
                    handleCellClick={handleCellClick}
                    handleSelectHeaderCheckBox={handleSelectHeaderCheckBox}
                    selectedIds={selectedIds}
                    selectedOriginIds={selectedOriginIds}
                    currentSortDetails={currentSortDetails}
                    currentClickedRowId={currentClickedRowId}
                    handleCheckboxChange={handleCheckboxChange}
                    tableName={tableName}
                />
            {(sortedData && !isHomePageTable)
                }

        </>
    )

};

Table.propTypes = {
    columnsMapping: PropTypes.objectOf(PropTypes.shape({
        getValue: PropTypes.func.isRequired,
        getValueForSort: PropTypes.func,
        classes: PropTypes.shape({
            noPadding: PropTypes.bool,
            isTitleCase: PropTypes.bool,
        }),
    }).isRequired).isRequired,
    data: PropTypes.arrayOf(PropTypes.object.isRequired,),
    className: PropTypes.string,
    options: PropTypes.shape({
        multichoice: PropTypes.bool,
        disableTableView: PropTypes.bool,
        renderExpandedListViewItem: PropTypes.func,
    }),
    renderDetailsView: PropTypes.func,
    tableName: PropTypes.string,
};

export default Table;
