import React from 'react';
import styles from './Tabs.module.scss';
import clsx from 'clsx';
import PropTypes from 'prop-types'

const Tabs = ({
    tabs,
    currentTab,
    handleTabChange,
    leftSection,
    rightSection,
    classes = {}
}) => {
    return (
        <div className={clsx(styles.root, classes.root)}>
            {leftSection && <div className={clsx(styles.leftSectionWrapper, classes.leftSectionWrapper)}>{leftSection}</div>}
            <div className={styles.tabs}>
                {tabs.map(tab =>
                    <button
                        key={tab}
                        className={clsx({
                            [styles.tab]: true,
                            [styles.active]: tab === currentTab
                        }, classes.tab)}
                        onClick={() => handleTabChange(tab)}
                    >
                        {tab}
                    </button>
                )}
            </div>
            {rightSection && <div className={clsx(styles.rightSectionWrapper, classes.rightSectionWrapper)}>{rightSection}</div>}
        </div>
    )
};

export default Tabs;

Tabs.propTypes = {
    tabs: PropTypes.arrayOf(PropTypes.string.isRequired,).isRequired,
    currentTab: PropTypes.string.isRequired,
    handleTabChange: PropTypes.func.isRequired,
    leftSection: PropTypes.node,
    rightSection: PropTypes.node,
    classes: PropTypes.object,
}
