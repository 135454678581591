import styles from './TableView.module.scss';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { ReactComponent as DownArrowIcon } from 'assets/icons/arrow-down.svg';
import Checkbox from 'pages/common/checkbox/Checkbox';
import { ReactComponent as CheckboxEmptyIcon } from 'assets/icons/checkbox-empty-green.svg';
import { ReactComponent as CheckboxCheckedIcon } from 'assets/icons/checkbox-header-selected.svg';
import useBreakpoints from 'utility/hooks/useBreakpoints';
import { useMemo, useState } from 'react';
import MultichoiceConfirmationSnackbar from './../multichoice-confirmation-snackbar/MultichoiceConfirmationSnackbar';

const TableView = ({
    columnsMapping: columnsMappingFromParent,
    data,
    className: classNameFromParent,
    options = {},
    onMultichoiceConfirm,
    handleHeaderClick,
    handleCellClick,
    handleSelectHeaderCheckBox,
    selectedIds,
    selectedOriginIds,
    currentSortDetails,
    currentClickedRowId,
    handleCheckboxChange,
    tableName,
}) => {
    const { isDesktopWidth } = useBreakpoints();
    const [currentHoveringRowId, setCurrentHoveringRowId] = useState(null);
    const columnsMapping = useMemo(() => ({
        ...options.multichoice && columnsMappingFromParent,

    }
    ), [columnsMappingFromParent, handleCheckboxChange, options.multichoice]);


    const gridTemplateColumnsStyle = useMemo(() => {
        const allColumnsLength = Object.keys(columnsMapping).length;
        const noPaddingColumnsLength = Object.values(columnsMapping).filter(column => column.classes?.noPadding).length;
        let gridTemplateColumnsStyle = `repeat(${allColumnsLength - noPaddingColumnsLength}, auto)`;

        if (noPaddingColumnsLength) {
            gridTemplateColumnsStyle += ` repeat(${noPaddingColumnsLength}, min-content)`
        }

        return gridTemplateColumnsStyle;
    }, [columnsMapping]);

    return (
        <>
            <div
                className={clsx(styles.table, classNameFromParent)}
                style={{ gridTemplateColumns: gridTemplateColumnsStyle }}
            >
                {Object.entries(columnsMapping).map(([header, column]) =>
                    <div
                        key={header}
                        className={clsx({
                            [styles.header]: true,
                            [styles.noPadding]: column.classes?.noPadding,
                            [styles.unclickable]: header === 'select',
                        })}
                        onClick={() => header !== 'select' && handleHeaderClick(header)}
                    >
                        {
                            header === 'select'
                                ?
                                <div className={styles.selectHeader}>
                                    <Checkbox
                                        classes={{
                                            root: !selectedIds.length ?? styles.unclickable,
                                        }}
                                        isChecked={!!selectedIds.length}
                                        onClick={(_, e) => handleSelectHeaderCheckBox(e)}
                                        checkedIcon={<CheckboxCheckedIcon />}
                                        emptyIcon={<CheckboxEmptyIcon />}
                                    />
                                    <span>select</span>
                                </div >
                                :
                                <>
                                    <span className={styles.headerText}>{header}</span>
                                    <DownArrowIcon
                                        className={clsx({
                                            [styles.headerIcon]: true,
                                            [styles.hidden]: currentSortDetails?.field !== header,
                                            [styles.descending]: !currentSortDetails?.isAscending,
                                        })}
                                    />
                                </>
                        }
                    </div>
                )}
                {

                    data?.length > 0 ?

                        data?.map(row =>
                        {

                            return Object.entries(columnsMapping).map(([header, column]) =>



                                <div
                                    key={`${header}_${row.origin_op_id}`}
                                    onMouseEnter={() => isDesktopWidth && options.multichoice && setCurrentHoveringRowId(row.origin_op_id)}
                                    onMouseLeave={() => isDesktopWidth && options.multichoice && setCurrentHoveringRowId(null)}
                                    onClick={() => handleCellClick(row.origin_op_id,row.operation_id)}
                                    className={clsx({
                                        [styles.cell]: true,
                                        [styles.noPadding]: column.classes?.noPadding,
                                        [styles.isTitleCase]: column.classes?.isTitleCase,
                                        [styles.clickable]: options.multichoice,
                                        [styles.selected]: options.multichoice && selectedIds.includes(row.origin_op_id),
                                        [styles.clicked]: options.multichoice && currentClickedRowId === row.origin_op_id,
                                        [styles.hover]: options.multichoice && currentHoveringRowId === row.origin_op_id,
                                    })}
                                >
                                    {column.getValue(row, header === 'select' ? selectedIds : null)}
                                </div>
                            )}



                        )
                        : <span>No records found</span>
                }
            </div>
            {
                options.multichoice
                &&
                <MultichoiceConfirmationSnackbar
                    selectedIds={selectedIds}
                    tableName={tableName}
                    onConfirm={onMultichoiceConfirm}
                />
            }
        </>
    )
};

TableView.propTypes = {
    columnsMapping: PropTypes.objectOf(PropTypes.shape({
        getValue: PropTypes.func.isRequired,
        getValueForSort: PropTypes.func,
        classes: PropTypes.shape({
            noPadding: PropTypes.bool,
            isTitleCase: PropTypes.bool,
        }),
    }).isRequired).isRequired,
    data: PropTypes.arrayOf(PropTypes.object.isRequired,),
    className: PropTypes.string,
    options: PropTypes.shape({
        multichoice: PropTypes.bool,
    }),
    onMultichoiceConfirm: PropTypes.func,
    handleHeaderClick: PropTypes.func,
    handleCellClick: PropTypes.func,
    handleSelectHeaderCheckBox: PropTypes.func,
    selectedIds: PropTypes.arrayOf(PropTypes.string,),
    currentSortDetails: PropTypes.shape({
        field: PropTypes.string.isRequired,
        isAscending: PropTypes.bool.isRequired,
    }),
    currentClickedRowId: PropTypes.string,
    handleCheckboxChange: PropTypes.func,
    tableName: PropTypes.string,
};

export default TableView;
