import React, { useState } from 'react'
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import styles from './RecentOperations.module.scss';
import clsx from 'clsx';
import OperationsSection from 'pages/farm-dashboard/operations-section/OperationsSection';
import TableFilterMenu from 'pages/farm-dashboard/table-filter-menu/TableFilterMenu';
import { ReactComponent as HamburgerMenuIcon } from 'assets/icons/hamburger-menu-grey.svg';
import RangeDatePicker from 'pages/common/range-date-picker/RangeDatePicker';
const RecentOperations = ({ handleSectionChange }) => {
    const [selectedStartDate,   setSelectedStartDate] = useState(null);
    const [selectedEndDate, setSelectedEndDate] = useState(null);
    const [columnsFilter, setColumnsFilter] = useState({
        'duration': true,
        'spray mode': true,
        machinery: true,
        field: true,
        date: true
    })
    const [isColumnsFilterMenuOpen, setisColumnsFilterMenuOpen] = useState(false)
    const getFormattedDate = date => date ? dayjs(date).format("YYYY-MM-DD") : null;
    const history = useHistory();
    return (
        <div className={styles.root}>
            <div className={styles.header}>
                <div className={styles.header_leftSide}>
                    <div className={styles.datepickerWrapper}>
                        <div className={styles.datepickerRoot}>
                            <RangeDatePicker
                                selectedStartDate={selectedStartDate}
                                selectedEndDate={selectedEndDate}
                                setSelectedStartDate={setSelectedStartDate}
                                setSelectedEndDate={setSelectedEndDate}
                            />
                        </div>
                    </div>

                </div>
                <div className={styles.header_rightSide}>
                    <div className={styles.menu_wrapper}>
                        <HamburgerMenuIcon
                            onClick={() => setisColumnsFilterMenuOpen(!isColumnsFilterMenuOpen)}
                            className={clsx({
                                [styles.open_menu]: isColumnsFilterMenuOpen,
                            }, styles.hamburger_icon)}
                        />
                        {isColumnsFilterMenuOpen
                            && <TableFilterMenu classes={{ root: styles.menu_filter }} setColumnsFilter={setColumnsFilter} columnsFilter={columnsFilter} />}
                    </div>
                </div>
            </div>
            <OperationsSection
                className={styles.sectionWrapper}
                startDate={getFormattedDate(selectedStartDate)}
                endDate={getFormattedDate(selectedEndDate)}
                isHomePageTable={false}
                columnsFilter={columnsFilter}
                handleSectionChange={handleSectionChange}
            />
        </div>
    )
}

export default RecentOperations