import Tabs from 'pages/common/tabs/Tabs';
import React, { useState } from 'react';
import styles from './AdminPage.module.scss';
import useBreakpoints from 'utility/hooks/useBreakpoints';
import Button from 'pages/common/button/Button';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import UsersTable from './users-table/UsersTable';
import RolesTable from './roles-table/RolesTable';

const tabs = [
    'Users',
    'Roles'
];

const AdminPage = () => {
    const [currentTab, setCurrentTab] = useState(tabs[0]);
    const [showAddPopup, setShowAddPopup] = useState(false);
    const { isDesktopWidth } = useBreakpoints();

    return (
        <div className={styles.root}>
            <Tabs
                tabs={tabs}
                currentTab={currentTab}
                handleTabChange={setCurrentTab}
                leftSection={isDesktopWidth &&
                    <span className={styles.tabsHeader}>Users and Roles</span>
                }
                rightSection={isDesktopWidth &&
                    <Button onClick={() => setShowAddPopup(true)}>
                        <PlusIcon />
                        <span>Add</span>
                    </Button>
                }
            />
            <div className={styles.content}>
                {
                    currentTab === 'Users' &&
                    <UsersTable
                        className={styles.tableWrapper}
                        showAddPopup={showAddPopup}
                        handleCloseAddPopup={() => setShowAddPopup(false)}
                    />
                }
                {
                    currentTab === 'Roles' &&
                    <RolesTable
                        className={styles.tableWrapper}
                        showAddPopup={showAddPopup}
                        handleCloseAddPopup={() => setShowAddPopup(false)}
                    />
                }
            </div>
        </div>
    )
};

export default AdminPage;
