import React, { useEffect, useRef, useState, useCallback } from "react";
import { GoogleMap, MarkerClusterer } from "@react-google-maps/api";
import clsx from 'clsx';
import styles from './Map.module.scss';
import useBreakpoints from 'utility/hooks/useBreakpoints';
import CustomMarker from "./CustomMarker";
import {ReactComponent as Loading} from "../../../assets/icons/LoadingAnimated.svg";
import {renderToStaticMarkup} from "react-dom/server";
import Modal from 'react-bootstrap/Modal';
import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';
import { gql, useQuery } from '@apollo/client';
import Switch from "react-switch";
const spray_mapping = require('./ColorMaping');

//for work with dataheatmap example file - change operationsHeatMap to it
// var dataheatmap = require('./heatmap-1685557065-with-images.json');


const Map = (
    {
        setHeatMapFlag,
        HeatMapFlag,
        fieldsPolygonsArr,
        operationsData,
        cameraSnapLocation = [],
        currentTab,
        operationPolygonArr,
        operationsHeatMap = [],
        featureFlagImages,
    }) => {
    const google = window.google;
    const mapRef = useRef(null);
    const [zoomThreshold,] = useState(15);
    const { isMobileWidth, isTabletWidth } = useBreakpoints();
    const [center,] = useState({ lat: 33, lng: 35 });
    const [loadingHeatMap, setLoading] = useState(false);
    const [layerOptions, setLayerOptions] = useState(
        {
            'SPRAYING HEATMAP': {
                isVisible: false, isButtonPressed: false
            },
            'FIELD IMAGES': {
                isVisible: false, isButtonPressed: false
            },
            'OPERATIONS': {
                isVisible: true, isButtonPressed: true
            },
            'FIELDS': {
                isVisible: true, isButtonPressed: true
            }
        }
    );
    const [heatmapDataFormatted, setHeatmapDataFormatted] = useState([]);
    const [activeMarker, setActiveMarker] = useState(null);
    const [gridSquares, setgridSquares] = useState([]);
    const [clickableSquares, setClickableSquares] = useState([]);
    const [colorsMapping, setColorsMapping] = useState([]);
    const [selectedRectangle, setSelectedRectangle] = useState(null);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isToggleSet, setIsToggleSet] = useState(true);
    const [enableToggle, setEnableToggle] = useState(true);

    const closeSlideshowPopup = () => {
        setIsPopupOpen(false);
    };

    useEffect(() => {
        if (isToggleSet) {
            for (let i = 0; i < clickableSquares.length; i++) {
                google.maps.event.addListener(clickableSquares[i], 'click', () => {
                    handleRectangleClick(clickableSquares[i]);
                });
            }
        }
    }, [clickableSquares, isToggleSet]);



    const fitBounds = useCallback(() => {
        if (!mapRef.current) {
            return;
        }
        const bounds = new google.maps.LatLngBounds();

        if (fieldsPolygonsArr.polygon){
            for (let i = 0; i < fieldsPolygonsArr.polygon.length; i++) {
                for (let j = 0; j < fieldsPolygonsArr.polygon[i].length; j++) {
                    bounds.extend({ lat: fieldsPolygonsArr.polygon[i][j].lat, lng: fieldsPolygonsArr.polygon[i][j].lng });
                }
            }
        }
        mapRef.current.fitBounds(bounds);
    }, [google.maps, fieldsPolygonsArr]);

    useEffect(() => {
        setHeatmapDataFormatted(operationsHeatMap);
        if  (operationsHeatMap && (typeof(operationsHeatMap.HeatmapOperation) !== "undefined")){
            onZoomChanged(mapRef?.current?.zoom, mapRef?.current);
            setLoading(true)
        }
        else{
            if (!HeatMapFlag){
                setHeatMapFlag(false)
                setLoading(true)

            }}


    }, [google.maps.LatLng, operationsHeatMap, isToggleSet]);


    useEffect(() => {
        if (operationsData.spray_mode) {
            const colors_range = spray_mapping.default[operationsData.spray_mode]? spray_mapping.default[operationsData.spray_mode] : spray_mapping.default['Corn']
            setColorsMapping(colors_range)

            if (operationsData.spray_mode=='Spray Canopy' || operationsData.spray_mode=='Data collection'|| HeatMapFlag==false ){
                setHeatMapFlag(false)
                setLoading(true)
            }
        }
    }, [operationsData, HeatMapFlag]);

    const Legend = ({ colorDict }) => {
        return (
            <div>
                {Object.entries(colorDict).map(([label, values]) => (
                    <div
                        key={label}
                        className="cbox"
                        style={{
                            backgroundColor: values.color,
                            padding: '5px',
                            boxSizing: 'border-box',
                            opacity: '0.8',
                        }}
                    >
                        <center>{values.min + '-' + Math.round(values.max)}</center>
                    </div>
                ))}
            </div>
        );
    };

    useEffect(() => {
        if (!mapRef.current) {
            return;
        }

        const legendControlDiv = document.createElement('div');
        const legendControl = new CustomControl(legendControlDiv, colorsMapping);
        legendControlDiv.index = 1; // Set the position of the control on the map
        mapRef.current.controls[window.google.maps.ControlPosition.TOP_RIGHT].push(
            legendControlDiv
        );

        // Define the CustomControl class
        function CustomControl(controlDiv, colorDict) {
            const legendElement = document.createElement('div');
            // legendElement.style.padding = '10px';
            legendElement.style.borderRadius = '5px';


            const legendJSX = <Legend colorDict={colorDict} />;
            const legendString = renderToStaticMarkup(legendJSX);
            legendElement.innerHTML = legendString;

            controlDiv.appendChild(legendElement);
        }
    }, [mapRef.current,colorsMapping]);

    useEffect(() => {
        fitBounds();
    }, [fitBounds, operationsData, fieldsPolygonsArr]);

    function getColor(wight) {
        let idx = -1;
        let color = null;

        colorsMapping.every((a, index) => {
            if (wight >= a.min && wight < a.max) {
                idx = index;
                color = a.color;
                return false;
            }
            return true;
        });
        return {'color': color, 'index': idx};
    }

    const onLoadMap = map => {
        mapRef.current = map;
        fitBounds();
        // setHeatmapDataFormatted(operationsHeatMap);
        operationsHeatMap =[];
        const clusterer = new MarkerClusterer(map, [], {
        });

    };

    const containerStyle = () => {
        const defaultStyle = {
            position: 'relative',
            width: '100%',
            height: '500px',
            marginTop: '15px',
        };

        if (isMobileWidth) {
            return { ...defaultStyle, height: '217px' };
        }

        if (isTabletWidth) {
            return { ...defaultStyle, height: '353px' };
        }
        return defaultStyle;
    };

    const handleActiveMarker = (marker) => {
        if (marker === activeMarker) {
            return;
        }
        setActiveMarker(marker);
    };
    const lineSymbol = {
        path: "M 0,-1 0,1",
        strokeOpacity: 1,
        scale: 1.3,
        strokeColor: 'white',

    };

    const operationPolygonOptions = {

        strokeOpacity: 0,
        fillOpacity: 0,
        zIndex: 1,
        icons: [
            {
                icon: lineSymbol,
                offset: '0%',
                repeat: '10px'
            }
        ]
    };

    function clearSquares(){
        for (var square = 0; square < gridSquares.length; square++) {
            if (gridSquares[square]){
                gridSquares[square].setMap(null);
                gridSquares[square] = null;
            }
        }
        setgridSquares([]);
    }

    const handleRectangleClick = (rectangle) => {
        setSelectedRectangle(rectangle);
        setIsPopupOpen(true);
    };

    const handleToggleChange = () => {
        setIsToggleSet(!isToggleSet)
    };

    function createSquares(zoom, map_current, bounds, heatmap_data){
        var color_obj = getColor(Math.round(heatmap_data.pcnt));
        var opacity = 0.5
        if (heatmap_data.pcnt === -1){
            return;
        }
        var coordinate_right = new google.maps.LatLng(heatmap_data.point1_lat, heatmap_data.point1_lng)
        var coordinate_left = new google.maps.LatLng(heatmap_data.point2_lat, heatmap_data.point2_lng)
        if (bounds.contains(coordinate_right) || bounds.contains(coordinate_left)) {
            var click = false
            var Rectangle = new google.maps.Rectangle();
            if (heatmap_data.images && heatmap_data.images.length && color_obj.index >= 4) {
                click = true
            }
            Rectangle.setOptions({
                strokeColor: 'black',
                strokeOpacity: click && isToggleSet ? 1.0 : 0.5,
                strokeWeight: click && isToggleSet ? 2.0 : 0.5,
                fillColor: color_obj.color,
                fillOpacity: opacity,
                map: map_current,
                bounds: new google.maps.LatLngBounds(
                    new google.maps.LatLng(heatmap_data.point1_lat, heatmap_data.point1_lng),
                    new google.maps.LatLng(heatmap_data.point2_lat, heatmap_data.point2_lng)),
                imagesUrl: heatmap_data.images,
                clickable: click && isToggleSet
            });
        }
        return  Rectangle
    }

    const onZoomChanged = (zoom, map_current) => {

        if (map_current === null) {
            setLoading(true)
            return;
        }

        var bounds = map_current.getBounds();
        if (zoom !== null) {
            clearSquares();
            var grid_per_level = [];
            var clickable = [];
            var Rectangle;
            var maxZoom = 16;
            var hasClickable = false;
            var foundLevel = false;

            if (typeof (zoom) !== "undefined") {
                if ((typeof(operationsHeatMap) !== "undefined" && operationsHeatMap !== null) && (typeof(operationsHeatMap.HeatmapOperation) !== "undefined" && operationsHeatMap.HeatmapOperation !== null)) {
                    var current_level = Object.keys(operationsHeatMap.HeatmapOperation).length - (zoom - maxZoom) - 1;
                    while (!foundLevel) {
                        if (`zoom_${current_level}` in operationsHeatMap.HeatmapOperation && operationsHeatMap.HeatmapOperation[`zoom_${current_level}`] !== null && operationsHeatMap.HeatmapOperation[`zoom_${current_level}`].length !== 0) {
                            foundLevel = true;
                        } else {
                            current_level -= 1;
                        }
                    }
                    var heat_map_on_zoom_level = operationsHeatMap.HeatmapOperation["zoom_" + current_level]
                    for (var rec = 0; rec < heat_map_on_zoom_level.length; rec++) {
                        Rectangle = createSquares(zoom, map_current, bounds, operationsHeatMap.HeatmapOperation[`zoom_${current_level}`][rec])
                        if (typeof (Rectangle) !== "undefined") {
                            grid_per_level.push(Rectangle);
                            if (Rectangle.imagesUrl) {
                                clickable.push(Rectangle)
                                hasClickable = true;
                            }
                        }
                    }
                }
                setgridSquares(grid_per_level);
                setClickableSquares(clickable);
                setLoading(true);
                setEnableToggle(hasClickable);
                if (zoom && zoom <= zoomThreshold) {
                    let layersOptions = {
                        ...layerOptions,
                        'SPRAYING HEATMAP': {
                            ...layerOptions['SPRAYING HEATMAP'],
                            isVisible: false,
                        },
                    };
                    if (featureFlagImages) {
                        layersOptions = {
                            ...layersOptions,
                            'FIELD IMAGES': {
                                ...layerOptions['FIELD IMAGES'],
                                isVisible: false,
                            },
                        };
                    }
                    setLayerOptions(
                        layersOptions
                    );
                } else {
                    const newLayerObj = {};
                    const layersObjectKeys = Object.keys(layerOptions);
                    for (const key of layersObjectKeys) {
                        if (layerOptions[key].isButtonPressed) {
                            newLayerObj[key] = {...layerOptions[key], isVisible: true,};
                            continue;
                        } else {
                            newLayerObj[key] = {...layerOptions[key], isVisible: false,};
                        }
                    }
                    setLayerOptions(newLayerObj);
                }
            }
        }
    };

    const ToggleSwitch = ({checked, onChange}) => (
            <label style={{ display: 'flex', alignItems: 'center', gap: '10px', cursor: 'pointer'}}>
                <span>Show images </span>
                <Switch
                type="checkbox"
                className="toggle-switch-checkbox"
                checked={checked}
                onChange={e => onChange()}/>
            </label>

    );

    const GET_IMAGES = gql`
    query ($filters: ImageFiltersInput){
    getImages(filters: $filters)
  }`;

    const SlideshowPopup = ({ images, isOpen, onRequestClose }) => {
        const [loadedData, setLoadedData] = useState([]);
        const [isError, setIsError] = useState(false);

        const { loading, error, data } = useQuery(GET_IMAGES, {
            variables: {
                filters: {
                    image_urls: images,
                    start_time: operationsData.start_time
                }
            },
            skip: !images, // Skip the query if currentImagePath is not set
        });

        useEffect(() => {
            if (!loading && !error && data) {
                setLoadedData(data.getImages);
            }
            if (error) {
                setIsError(true);
            }
        }, [loading, error, data]);

        if (loading) {
            return (<Modal show={loading} centered><Modal.Body>Loading...</Modal.Body></Modal>);
        }
        // check if data is empty, if yes - don't show div
        if (!loadedData || !loadedData.length) {
            console.log("No images found")
            return null;
        }

        if (error) {
            return (<Modal show={isError} centered><Modal.Body>Error: {error.message}</Modal.Body></Modal>);
        }

        return (
            <Modal show={isOpen} onHide={onRequestClose} size="lg" centered>
                <Modal.Body>
                    <Carousel autoFocus={true} useKeyboardArrows={true} autoPlay={false} interval={100000}>
                        {loadedData.map((image, index) => (
                            <Carousel.Item key={index}>
                                <img className="d-block w-100" src={`data:image/jpeg;base64,${image}`} alt={images[index]}/>
                            </Carousel.Item>
                        ))}
                    </Carousel>
                    {/*<button className="prev-button" onClick={handlePrevSlide}>Previous</button>*/}
                    {/*<button className="next-button" onClick={handleNextSlide}>Next</button>*/}
                    {/*<button className="close-button" onClick={onRequestClose}>Close</button>*/}
                </Modal.Body>
            </Modal>
        );
    };



    return loadingHeatMap ? (HeatMapFlag?
        <div>
            <GoogleMap
                mapContainerStyle={containerStyle()}
                id={'google-map'}
                center={center}
                zoom={10}
                onLoad={onLoadMap}
                onZoomChanged={() => onZoomChanged(mapRef?.current?.zoom, mapRef?.current )}
                onDrag={() => onZoomChanged(mapRef?.current?.zoom, mapRef?.current )}
                onIdle={() => onZoomChanged(mapRef?.current?.zoom, mapRef?.current )}
                mapType={"satellite"}
                options={
                    {
                        streetViewControl: false,
                        mapTypeId: "satellite",
                        mapTypeControlOptions: {
                            mapTypeIds: []
                        },
                        minZoom: 16,
                        maxZoom: 20,
                        fullscreenControl: !isToggleSet || !enableToggle
                    }
                }
            >
                <div id="layerMenu" >
                </div>



                {layerOptions['FIELD IMAGES']?.isVisible &&
                    <MarkerClusterer>
                        {clusterer =>
                            cameraSnapLocation.map(({ lat, lng, id, imgSrc }) => (
                                <CustomMarker
                                    position={{ lat, lng }}
                                    clusterer={clusterer}
                                    handleActiveMarker={(id) => handleActiveMarker(id)}
                                    key={id}
                                    id={id}
                                    activeMarker={activeMarker}
                                    setActiveMarker={(id) => setActiveMarker(id)}
                                    imgSrc={imgSrc}

                                />
                            ))
                        }
                    </MarkerClusterer>}


            </GoogleMap>

            { enableToggle && (
                <div style={{display: "flex", justifyContent: "left", alignItems: "left", marginTop: '20px'}}>
                <ToggleSwitch checked={isToggleSet} onChange={handleToggleChange}/>
            </div>)}
            {  selectedRectangle && isPopupOpen && ( <div className="popup-overlay" style={{ position: 'absolute', top: 0, left: 0, width: '200px' }}>

            <div>
                <SlideshowPopup
                    images={selectedRectangle.imagesUrl}
                    isOpen={isPopupOpen}
                    onRequestClose={closeSlideshowPopup}
                />
            </div>
        </div> )}</div>


        : (
            <div className={clsx(styles.heatmaptext)}>
                <center><br />Heatmap not generated <br /></center>
            </div>
        ) ) : <Loading style={{ background: "transparnt", margin: "auto", display: "block", shapeRendering: "auto" }} />;

};


export default React.memo(Map);
