import React, { useRef } from 'react';
import styles from './SideMenu.module.scss';
import clsx from 'clsx';
import useClickAwayListener from 'utility/hooks/useClickAwayListener';
import useBreakpoints from 'utility/hooks/useBreakpoints';
import greeneyeLogo from 'assets/icons/greeneye-logo.svg';
import { ReactComponent as HomepageLinkIcon } from 'assets/icons/homepage-link.svg';
import { ReactComponent as AdminLinkIcon } from 'assets/icons/admin.svg';
import { NavLink } from 'react-router-dom';
import ROUTES from 'routes';
import SYSTEM_ROLE_TYPES from 'utility/types/systemRoleTypes';
import useAlerts from 'utility/hooks/useAlerts';

const menuLinks = [
    {
        to: ROUTES.HOME,
        icon: <HomepageLinkIcon className={styles.linkIcon} />,
        text: 'Home',
    },
    // {
    //     to: ROUTES.FARM_DASHBOARD,
    //     icon: <FarmDashboardLinkIcon className={styles.linkIcon} />,
    //     text: 'Farm Dashboard',
    // },
    {
        to: ROUTES.ADMIN,
        icon: <AdminLinkIcon className={styles.linkIcon} />,
        text: 'Admin',
        systemRole: SYSTEM_ROLE_TYPES.ADMIN
    },

];

const SideMenu = ({ isOpen, closeMenu, userDetails }) => {
    const { notImplementedAlert } = useAlerts();
    const { isMobileWidth, isDesktopWidth } = useBreakpoints();
    const menuRef = useRef();
    useClickAwayListener(menuRef, () => {
        if (isMobileWidth) {
            closeMenu();
        }
    });

    return (
        <div
            className={clsx({ [styles.root]: true, [styles.isClose]: !isOpen })}
            ref={menuRef}
        >
            <div className={styles.header}>
                {
                    isMobileWidth
                    &&
                    <div className={styles.profileSectionWrapper}>
                        <button className={styles.profileButton} onClick={() => notImplementedAlert()}>
                            <img
                                className={styles.profileImage}
                                src={userDetails.profileImageUrl}
                                alt="profile button"
                            />
                        </button>
                        <span className={styles.name}>{userDetails.name}</span>
                    </div>
                }
                <img
                    className={styles.logo}
                    src={greeneyeLogo}
                    alt="greeneye logo"
                />
            </div>
            <div className={styles.linksWrapper}>
                {
                    menuLinks.map(link => !link.systemRole || link.systemRole === userDetails.systemRole
                        ? <NavLink
                            key={link.to}
                            to={link.to}
                            className={styles.linkWrapper}
                            activeClassName={styles.active}
                            exact
                            onClick={() => closeMenu()}
                        >
                            {link.icon}
                            {isMobileWidth && <span className={styles.linkText}>{link.text}</span>}
                            {isDesktopWidth && <div className={styles.tooltip}>{link.text}</div>}
                        </NavLink>
                        : null
                    )
                }
            </div>
            {
                isMobileWidth
                &&
                <div className={styles.footer}>All rights reserved to Greeneye inc. 2021</div>
            }
        </div>
    );
};

export default SideMenu;
