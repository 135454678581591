import React, { useState, useCallback } from 'react';
import styles from './HomePage.module.scss';
import RecentOperations from './RecentOperations';

const OPETIONS_OPTIONS = "operation";

const HomePage = ({ currentUser }) => {
    const [displaySection, setDisplaySection] = useState([OPETIONS_OPTIONS]);

    const handleSectionChange = useCallback((type, optionToggle) => {
        if (optionToggle === "close") {
            setDisplaySection([ OPETIONS_OPTIONS]);
            return;
        }
        if (type === OPETIONS_OPTIONS) {
            setDisplaySection([OPETIONS_OPTIONS]);
        }
    }, []);

    return (
        <div className={styles.root}>
            {displaySection.includes(OPETIONS_OPTIONS) && <RecentOperations handleSectionChange={handleSectionChange} />}
            {/*{displaySection.includes(TOTAL_METRICS) && <TotalMetrics currentUser={currentUser} />}*/}
        </div>
    );
};

export default HomePage;
