import { useSnackbar } from "notistack";

const useAlerts = () => {
    const { enqueueSnackbar } = useSnackbar();
    const errorAlert = message => enqueueSnackbar(message ?? 'An Error Has Occured', { variant: 'error' });
    const updateSuccessAlert = message => enqueueSnackbar(message ?? 'Successfully Updated!', { variant: 'success' });
    const createSuccessAlert = message => enqueueSnackbar(message ?? 'Successfully Created!', { variant: 'success' });
    const notImplementedAlert = message => enqueueSnackbar(message ?? 'Not Implemented Yet', { variant: 'info' });

    return {
        errorAlert,
        updateSuccessAlert,
        createSuccessAlert,
        notImplementedAlert
    }
}

export default useAlerts;