import React, { useState } from 'react';
import styles from './PageHeader.module.scss';
import debugPageLinkIcon from 'assets/icons/tooltip-white.svg';
import searchIcon from 'assets/icons/search.svg';
import hamburgerMenuIcon from 'assets/icons/hamburger-menu.svg';
import useBreakpoints from 'utility/hooks/useBreakpoints';
import { Route, Switch } from 'react-router-dom';
import ROUTES from 'routes';
import useAlerts from 'utility/hooks/useAlerts';
import { NavLink } from 'react-router-dom';
import LogoutPopup from './LogoutPopup';

const PageHeader = ({ openMenu, profileImageUrl, userName }) => {
    const { isMobileWidth } = useBreakpoints();
    const { notImplementedAlert } = useAlerts();
    const [isLogoutPopupOn, setIsLogoutPopupOn] = useState(false);
    const [selectedFieldName] = useState(null);

    return (
        <div className={styles.root}>
            {
                isMobileWidth
                &&
                <button onClick={() => openMenu()}>
                    <img src={hamburgerMenuIcon} alt="menu button" />
                </button>
            }
            <h1 className={styles.title}>
                <Switch>
                    <Route exact path={ROUTES.HOME}>
                        {userName}
                    </Route>
                    <Route path={ROUTES.ADMIN}>
                        Admin
                    </Route>
                    <Route path={ROUTES.MAP}>
                        {userName}
                    </Route>
                    <Route path={ROUTES.DEBUG}>
                        Debug
                    </Route>
                    <Route path="*">
                        Home
                    </Route>
                </Switch>
            </h1>
            <div className={styles.lastSectionWrapper}>

                <NavLink to={ROUTES.DEBUG}>
                    <button>
                        <img src={debugPageLinkIcon} alt="debug page link" />
                    </button>
                </NavLink>
                {
                    !isMobileWidth
                    && <div>

                        <button className={styles.profileButton} onClick={() => setIsLogoutPopupOn(!isLogoutPopupOn)}>
                            <img className={styles.profileImage} src={profileImageUrl} alt="profile button" />
                        </button>
                        {isLogoutPopupOn && <LogoutPopup setIsLogoutPopupOn={setIsLogoutPopupOn} />}
                    </div>
                }
            </div>
        </div>
    );
};

export default PageHeader;
