import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom';
import { onError } from "apollo-link-error";
import { ApolloClient, ApolloLink, ApolloProvider, from, HttpLink, InMemoryCache } from '@apollo/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ROUTES from 'routes';

const httpLink = new HttpLink({ uri: process.env.REACT_APP_API_BASE_URL });

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.error(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      );

      const tokenExpiryErrorMessage = 'Context creation failed: jwt expired';
      const tokenMalformedErrorMessage = 'Context creation failed: jwt malformed';
      const tokenHeaderErrorMessage = 'authorization header is required';
      if (message === tokenExpiryErrorMessage || message === tokenHeaderErrorMessage || message === tokenMalformedErrorMessage) {
        localStorage.removeItem('t');
        client.clearStore();
        window.location.replace(ROUTES.LOGIN);
      }

    }
    );
  }

  if (networkError) {
    console.error(`[Network error]: ${networkError}`);
  }
});

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      ...!!localStorage.getItem('t') && { authorization: `Bearer ${localStorage.getItem('t')}` },
    }
  });

  return forward(operation);
});

const client = new ApolloClient({
  link: from([authMiddleware, errorLink, httpLink]),
  cache: new InMemoryCache(),
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <BrowserRouter>
      <App />
    </BrowserRouter >
  </ApolloProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
