import React from 'react'
import styles from './TableFilterMenu.module.scss';
import clsx from 'clsx';
import Checkbox from 'pages/common/checkbox/Checkbox';

const TableFilterMenu = ({ classes = {}, setColumnsFilter, columnsFilter }) => {
    return (
        <div className={clsx(styles.root, classes.root)}>
            <div className={styles.title} >
                CUSTOMIZE COLUMNS
            </div>
            <div className={styles.columns_wrapper}>
                {Object.keys(columnsFilter).map(column =>
                    <div className={styles.column_wrapper} key={column}>
                        <Checkbox
                            type="checkbox"
                            key={column}
                            isChecked={columnsFilter[column]}
                            onClick={() => setColumnsFilter({ ...columnsFilter, [column]: !columnsFilter[column] })}
                        />
                        <span className={styles.column_text}>{column}</span>
                    </div>
                )}
            </div>

        </div>
    )
}

export default TableFilterMenu
