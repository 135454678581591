import React from 'react';
import styles from './Popup.module.scss';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import Button from '../button/Button';
import PropTypes from 'prop-types'

const Popup = ({ onClose, onConfirm, title, children, confirmText }) => {
    return (
        <div className={styles.root}>
            <div className={styles.popupWrapper}>
                <button className={styles.closeButton} onClick={onClose}>
                    <CloseIcon />
                </button>
                {title && <div className={styles.title}>{title}</div>}
                <div className={styles.content}>{children}</div>
                <Button className={styles.confirmButton} onClick={onConfirm}>
                    {confirmText ?? 'confirm'}
                </Button>
            </div>
        </div>
    )
};

Popup.propTypes = {
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    title: PropTypes.string,
    confirmText: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
}

export default Popup;
