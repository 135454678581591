import { useJsApiLoader } from "@react-google-maps/api";
import React from 'react'
import Map from "./Map";
import { ReactComponent as Loading } from 'assets/icons/LoadingAnimated.svg';
const googleMapsLibraries = ['drawing', 'visualization', 'places']

const LoadMap = (props) => {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
        libraries: googleMapsLibraries
    })
    return isLoaded ? <Map {...props} /> : <Loading style={{ background: "transparnt", margin: "auto", display: "block", shapeRendering: "auto" }} />;
}

export default LoadMap
