import { gql, useMutation, useQuery } from '@apollo/client';
import Dropdown from 'pages/common/dropdown/Dropdown';
import Input from 'pages/common/input/Input';
import PasswordInput from 'pages/common/password-input/PasswordInput';
import Popup from 'pages/common/popup/Popup';
import React, { useState } from 'react';
import useAlerts from 'utility/hooks/useAlerts';
import LOCALE_TYPES from 'utility/types/localeTypes';
import styles from './EditUserForm.module.scss';

const GET_USER = gql`
    query getUser($id: ID!) {
        userById(id: $id) {
            id
            name
            email
            password
            locale
        }
    }
`;

const UPDATE_USER = gql`
    mutation updateUser($user: UpdateUserDetailsInput!) {
        updateUserDetails(userDetails: $user)
    }
`;

const EditUserForm = ({ onClose, onSubmitSuccess, id }) => {
    const { updateSuccessAlert, errorAlert } = useAlerts();
    const [updateUser] = useMutation(UPDATE_USER);
    const { loading } = useQuery(GET_USER, {
        variables: { id },
        onCompleted: ({ userById }) => {
            const {__typename, ...userDetails} = userById;
            setUserForm({ ...userDetails });
        },
        fetchPolicy: "no-cache"
    });
    const [userForm, setUserForm] = useState({
        name: '',
        email: '',
        password: '',
        locale: LOCALE_TYPES.en_US,
    });
    const [isFormDirty, setIsFormDirty] = useState(false)

    const handleFieldChange = (field, value) => {
        setUserForm(prevUserForm => ({
            ...prevUserForm,
            [field]: value,
        }));
    }

    const handleSubmit = async () => {
        setIsFormDirty(true);

        const requiredFields = ['name', 'email', 'password']
        if (requiredFields.some(field => !userForm[field])) {
            return;
        }

        const {email, ...userDetailsToUpdate} = userForm;
        
        try {
            await updateUser({
                variables: {
                    user: {
                        id,
                        ...userDetailsToUpdate
                    }
                }
            });
        } catch (error) {
            errorAlert();
            return;
        }

        updateSuccessAlert();
        onClose();
        onSubmitSuccess();
    }

    return (
        <Popup
            onClose={onClose}
            onConfirm={handleSubmit}
            title='Edit user'
            confirmText='Save'
        >
            {
                !loading
                    ?
                    <div className={styles.contentWrapper}>
                        <Input
                            label="e-mail"
                            name="email"
                            value={userForm.email}
                            onChange={handleFieldChange}
                            disabled={true}
                        />
                        <Input
                            label="name"
                            name="name"
                            value={userForm.name}
                            errorMessage={'required'}
                            hasError={isFormDirty && !userForm.name}
                            onChange={handleFieldChange}
                        />
                        <PasswordInput
                            value={userForm.password}
                            errorMessage={'required'}
                            hasError={isFormDirty && !userForm.password}
                            onChange={handleFieldChange}
                            includePasswordGenerator={true}
                            disabled={true}
                        />
                        <Dropdown
                            label="localization"
                            name="locale"
                            options={Object.values(LOCALE_TYPES)}
                            value={userForm.locale}
                            onChange={handleFieldChange}
                        />
                    </div>
                    : "loading..."
            }
        </Popup>
    )
};

export default EditUserForm;
