import React from 'react';
import Input from '../input/Input';
import styles from './PasswordInput.module.scss';
import { ReactComponent as VisibilityIcon } from 'assets/icons/eye.svg';
import { ReactComponent as VisibilityOffIcon } from 'assets/icons/eye-off.svg';
import { ReactComponent as PasswordGeneratorIcon } from 'assets/icons/generator.svg';
import PropTypes from 'prop-types'
import clsx from 'clsx';
import { useState } from 'react';
import generator from 'generate-password';

const PasswordInput = ({
    label = 'password',
    errorMessage,
    hasError,
    name = 'password',
    value,
    onChange,
    classes = {},
    disabled,
    includePasswordGenerator = false,
}) => {
    const [showPassword, setShowPassword] = useState(false);
    const [showVisibilityButton, setShowVisibilityButton] = useState(!includePasswordGenerator);

    const handleGeneratePasswordClicked = () => {
        const password = generator.generate();
        onChange(name, password);
        setShowVisibilityButton(true);
    }

    return (
        <div className={clsx(styles.root, classes.root)}>
            <Input
                classes={{
                    ...classes.input,
                    field: clsx({
                        [classes.input?.field]: true,
                        [styles.oneIconSuffix]: true,
                        [styles.twoIconsSuffix]: includePasswordGenerator && showVisibilityButton,
                    })
                }}
                label={label}
                name={name}
                value={value}
                type={showPassword ? 'text' : 'password'}
                onChange={onChange}
                errorMessage={errorMessage}
                hasError={hasError}
                disabled={disabled}
            />
            <button
                className={clsx({
                    [styles.iconButton]: true,
                    [styles.extraSpaceRight]: includePasswordGenerator,
                    [styles.hide]: !showVisibilityButton
                }, classes.iconButton)}
                onClick={() => setShowPassword(!showPassword)}
            >
                {
                    showPassword
                        ? <VisibilityOffIcon fill={'#234141'} />
                        : <VisibilityIcon fill={'#234141'} />
                }
            </button>
            {
                includePasswordGenerator &&
                <button className={styles.iconButton} onClick={handleGeneratePasswordClicked} >
                    <PasswordGeneratorIcon fill={'#234141'} />
                </button>
            }
        </div>
    )
};

PasswordInput.propTypes = {
    name: PropTypes.string,
    value: PropTypes.string.isRequired,
    label: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    errorMessage: PropTypes.string,
    hasError: PropTypes.bool,
    disabled: PropTypes.bool,
    includePasswordGenerator: PropTypes.bool,
    classes: PropTypes.object,
}

export default PasswordInput;
