import React from "react";


const data_color = [
        {color: '#00a700', min: 0.0, max: 5.0},
        {color: '#00c700', min: 5.0, max: 9.0},
        {color: '#7ee13d', min: 9.0, max: 13.0},
        {color: '#ffe238', min: 13.0, max: 17.0},
        {color: '#ffc233', min: 17.0, max: 21.0},
        {color: '#ff9924', min: 21.0, max: 25.0},
        {color: '#ff7b0f', min: 25.0, max: 30.0},
        {color: '#f5361f', min: 30.0, max: 40.0},
        {color: '#e60000', min: 40.0, max: 100.1}

    ];

const data_color_soy = [
        {color: '#00a700', min: 0.0, max: 10.0},
        {color: '#00c700', min: 10.0, max: 15.0},
        {color: '#7ee13d', min: 15.0, max: 20.0},
        {color: '#ffe238', min: 20.0, max: 25.0},
        {color: '#ffc233', min: 25.0, max: 30.0},
        {color: '#ff9924', min: 30.0, max: 35.0},
        {color: '#ff7b0f', min: 35.0, max: 40.0},
        {color: '#f5361f', min: 40.0, max: 60.0},
        {color: '#e60000', min: 60.0, max: 100.1}
    ];

const data_color_GOB = [
    {color: '#00a700', min: 0.0, max: 3.0},
    {color: '#00c700', min: 3.0, max: 7.0},
    {color: '#7ee13d', min: 7.0, max: 11.0},
    {color: '#ffe238', min: 11.0, max: 15.0},
    {color: '#ffc233', min: 15.0, max: 19.0},
    {color: '#ff9924', min: 19.0, max: 24.0},
    {color: '#ff7b0f', min: 24.0, max: 30.0},
    {color: '#f5361f', min: 30.0, max: 40.0},
    {color: '#e60000', min: 40.0, max: 100.1}
];

const spray_mapping = {
    'Corn':data_color,
    'Soy':data_color_soy,
    'Green On Brown': data_color_GOB
}

export default spray_mapping
