import React from 'react';
import styles from './MultichoiceConfirmationSnackbar.module.scss';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Button from 'pages/common/button/Button';

const MultichoiceConfirmationSnackbar = ({
    tableName,
    selectedIds,
    onConfirm,
}) => {
    return (
        <div className={clsx({
            [styles.root]: true,
            [styles.close]: !selectedIds?.length
        })}>
            <span className={styles.snackbarText}>
                {`${selectedIds?.length} ${selectedIds?.length > 1 ? tableName.toLowerCase() : tableName.slice(0, tableName.length - 1).toLowerCase()} selected`}
                </span>
            <Button className={styles.analyzeButton} onClick={onConfirm}>
                Analyze
            </Button>
        </div>
    )
};

MultichoiceConfirmationSnackbar.propTypes = {
    tableName: PropTypes.string.isRequired,
    selectedIds: PropTypes.arrayOf(PropTypes.string.isRequired,).isRequired,
    onConfirm: PropTypes.func.isRequired,
};

export default MultichoiceConfirmationSnackbar;
