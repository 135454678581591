import React from 'react';
import { gql, useQuery } from '@apollo/client';
import styles from './DebugPage.module.scss';
import clientPackageJson from '../../../package.json';

const GET_API_VERSION = gql`
    query getApiVersion {
        apiVersion
    }
`;

const DebugPage = () => {
    const { data } = useQuery(GET_API_VERSION);

    return (
        <div className={styles.root}>
            <div className={styles.versionWrapper}>
                <div className={styles.versionLabel}>Client:</div>
                <div className={styles.versionValue}>{clientPackageJson.version}</div>
            </div>
            <div className={styles.versionWrapper}>
                <div className={styles.versionLabel}>API:</div>
                <div className={styles.versionValue}>{data?.apiVersion ?? '-'}</div>
            </div>
        </div>
    )
};

export default DebugPage;
