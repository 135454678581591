import clsx from 'clsx'
import React from 'react'
import styles from './Widget.module.scss'
import PropTypes from 'prop-types'
import Tooltip from '../tooltip/Tooltip'

const Widget = ({ title, value, classes = {}, tooltip, unit, bottomSection, rightSection, dataCounterSection }) => {
    return (
        <div className={clsx(styles.root, classes.root)}>
            <Tooltip info={tooltip} className={styles.tooltip} />
            <div className={styles.leftSection}>
                <div className={classes.rightSectionTopSection}>
                    <div className={clsx(styles.title, classes.title)}>
                        {title}
                    </div>
                    <div className={clsx(styles.value, classes.value)}>
                        {value}
                        <span className={classes.unit}>{unit}</span>
                    </div>
                    {bottomSection && <div className={clsx(styles.bottomSection, classes.bottomSection)}>
                        {bottomSection}
                    </div>}
                </div>
                <div className={classes.rightSectionBottomSection}>
                    {dataCounterSection}
                </div>
            </div>
            {rightSection && <div className={classes.rightSection}>
                {rightSection}
            </div>}

        </div>
    )
}

Widget.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    rightSection: PropTypes.node,
    bottomSection: PropTypes.node,
    unit: PropTypes.string,
    classes: PropTypes.object,
    tooltip: PropTypes.string
}

export default Widget
