import React from 'react'
import Widget from '../widget/Widget';
import styles from './MachineryWidget.module.scss'
import PropTypes from 'prop-types'

const MachineryWidget = ({ machinery }) => {
    const machineryCounter = Object.keys(machinery.value).length;
    let widget;
    if (machineryCounter === 0) {
        widget = null;
    }
    if (machineryCounter === 1) {
        widget =
            <Widget
                tooltip={machinery.tooltip}
                title={machinery.title}
                unit={""}
                value={Object.keys(machinery.value).join()}
                classes={{ value: styles.machineryValue }} />
    }
    if (machineryCounter > 1) {
        widget = Object.keys(machinery.value).map((machine, i) =>
            <Widget
                tooltip={machinery.tooltip}
                key={i}
                title={`${machinery.title}: ${machinery.value[machine]} operation${machinery.value[machine] > 1 ? 's' : ''}`}
                unit={""}
                value={machine}
                classes={{ value: styles.machineryValue }} />
        )
    }
    return widget
}

MachineryWidget.propTypes = {
    machinery: PropTypes.object.isRequired,
}

export default MachineryWidget
