import React from 'react';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import styles from './PageHeader.module.scss';
import ROUTES from 'routes';

const LogoutPopup = ({ setIsLogoutPopupOn }) => {
    const logout = () => {
        localStorage.removeItem('t');
        window.location.replace(ROUTES.LOGIN);
        setIsLogoutPopupOn(false);
    };
    return (
        <div className={styles.LogoutPopupRoot}>
            <div className={styles.closeButton} >
                <CloseIcon onClick={() => setIsLogoutPopupOn(false)} />
            </div>
            <button className={styles.LogoutButton} onClick={logout}>Logout</button>
        </div>
    );
};

export default LogoutPopup;
